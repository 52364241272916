<template>
    <Transition name="opacity">
        <div v-if="showNewNotification" class="notification-new__wrapper" >
            <div class="notification-new__container" @click="$emit('emitNewNotificationEvent', $event)">{{ $t('SEE_NEW_NOTIFICATION') }}</div>
        </div>
    </Transition>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
    props: {
        showNewNotification: {
            type: Boolean,
        },
    },
    data() {
        return {
            // showNewNotification: false
        }
    },
   
    mounted() {

    },
    computed: {
        ...mapGetters([
            "hasNewNotification",
        ]),
    },
}
</script>

<style>
.notification-new__wrapper{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    z-index: 1;
    white-space: nowrap;
    pointer-events: none;
}
.notification-new__container{
    margin-top: 1rem;
    background-color: var(--bg-selected);
    border-radius: 1rem;
    color: black;
    padding: .5rem 1.5rem;
    font-size: 0.875rem;
    font-weight: 700;
    cursor: pointer;
    pointer-events: fill;
    transition: all .3s;
}
.notification-new__container:hover{
    background-color: #2e8fef;
    color: white;
}
.noti-wrapper .notification-new__container{
    margin-top: 1rem;
}
@media (max-width: 768px) {
    .noti-wrapper .notification-new__wrapper{
        width: calc(100% - 1.6rem);
        position: fixed
    }
}
</style>