<template>
      <!-- sign up info -->
    <ModalPopup ref="modalPopupSubmitRef">
        <div class="display-flex-align-center flex-col modal-confirmation__body">
            <div class="mb-15rem font-m tc">{{ message }}</div>
             <div class="w-100">
                <router-link :to="{ name: 'signup' , query: { callBackUrl: pathName ? pathName: currentPathName }}"> <button class="btn w-100 mb-1rem bg-white c-black">{{ $t('SIGNUP') }}</button></router-link>
                 <router-link :to="{ name: 'signin' , query: { callBackUrl:  pathName ? pathName: currentPathName }}"><button class="btn btn-outline w-100">{{ $t('LOGIN') }}</button></router-link>
            </div>
        </div>
    </ModalPopup>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import ModalPopup from '@/components/modal/ModalPopup.vue'

export default {
    props: [
        "message", 
        "pathName"
    ],
    
    components: {
        ModalPopup
    },

    computed:{
        ...mapGetters([
            'isMobile',
            'currentPathName'
        ]),
    },
    methods: {
        handleOpen() {
            this.$refs.modalPopupSubmitRef.handleOpen();
        }
    },
}
</script>

<style>

</style>