import createApp from "./main";



const { app, router, store } = createApp();

(async (r, a, s) => {
  const storeInitialState = window.INITIAL_DATA;

router.options.routes[0].redirect().path ="/th"
  await r.isReady();
  if (storeInitialState) {
    s.replaceState(storeInitialState);
  }

  a.mount("#app", true);


  
})(router, app, store);
