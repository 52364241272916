<template>
    <div v-if="isShowModal" ref="favModalRef" class="fav-login-tips__wrapper" :class="favLoginTips.type" tabindex="0" :style="{ top: style.top, left: style.left }">
        <div class="fav-login-tips__arrow">
            <svg width="18" height="15" viewBox="0 0 18 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9 0L17.6603 15L0.339746 15L9 0Z" fill="#4B616C"/>
            </svg>
        </div>
        <div class="fav-login-tips__container" >
            <p class="fav-login-tips__text" @click="handleBodyClick($event)">{{ $t(favLoginTips.message) }}</p>
            <div class="fav-login-tips__footer">
                <router-link :to="{ name: 'signin' , query: { callBackUrl: favLoginTips.callBackUrl } }" @click="toggleFavModal({ 'isClose' : true})" class="nav-pill">{{ $t("LOGIN") }}</router-link>
                <router-link :to="{ name: 'signup' , query: { callBackUrl: favLoginTips.callBackUrl } }" @click="toggleFavModal({ 'isClose' : true})" class="nav-pill selected">{{ $t("SIGNUP") }}</router-link>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
    data() {
        return {
            isShowModal: false,
            style: {
                top: '',
                left: ''
            }
        }
    },
    watch:{
        favLoginTips: {
            deep: true,
            handler(news, prev) {    
                if (news.isOpen) {
                    this.handleOpen(news.event, news.triggerOnParent); 
                } else {
                    this.handleClose()
                }
            }
        },
   },
    computed:{
        ...mapGetters([              
            'isMobile',
            'favLoginTips'
       ]),
    },
    mounted() {
        document.addEventListener('click', this.documentClick);
    },
    methods: {
        ...mapActions([
            "toggleFavModal"
        ]),
        handleOpen(event, triggerOnParent) {
            let targetedElement = event.target;
            if (triggerOnParent) {
                targetedElement = event.currentTarget;
            }
            this.isShowModal = true;

            this.$nextTick(() => {
                let favModalRef = this.$refs.favModalRef;
                if (this.favLoginTips.type === 'top') {
                    this.style.top = `${ this.getOffset(targetedElement).bottom }px`
                    this.style.left = `${ this.getOffset(targetedElement).right - (this.getOffset(targetedElement).width/2) - (favModalRef.getBoundingClientRect().width/2) }px`
                } else {
                    this.style.top = `${ this.getOffset(targetedElement).top - (favModalRef.getBoundingClientRect().height/2) + (targetedElement.getBoundingClientRect().height/2) }px`
                    this.style.left = `${ this.getOffset(targetedElement).left + (targetedElement.getBoundingClientRect().width) + 2 }px`
                }
            });
            event.stopPropagation();
        },

        handleClose() {
            this.isShowModal = false;
        },

        documentClick(e) {
            this.handleClose();
            e.stopPropagation();
        },
        
        getOffset(el) {
            const rect = el.getBoundingClientRect();
            return {
                left: rect.left + window.scrollX,
                right: rect.right + window.scrollX,
                top: rect.top + window.scrollY,
                bottom: rect.bottom + window.scrollY,
                width: rect.width
            };
        },

        handleBodyClick(event) {
            event.stopPropagation();
        }
    },
}
</script>

<style>
.fav-login-tips__wrapper{
    position: absolute;
    display: flex;
    align-items: center;
    z-index: 10;
}
.fav-login-tips__wrapper.top{
    flex-direction: column;
}
.fav-login-tips__container{
    background-color: #4B616C;
    padding: 1rem;
    width: 16.25rem;
    border-radius: 0.5rem;
    font-size: 0.875rem;
    z-index: 10;
}
.fav-login-tips__arrow{
    position: relative;
    left: 5.5px;
    top: 5px;
}
.fav-login-tips__arrow svg{
    transform: rotate(150deg);
}
.fav-login-tips__wrapper.top .fav-login-tips__arrow{
    position: relative;
    bottom: -5px;
    left: 0;
}
.fav-login-tips__wrapper.top .fav-login-tips__arrow svg{
    transform: rotate(0);
}
.fav-login-tips__footer{
    display: flex;
}
.fav-login-tips__text{
    margin-bottom: .5rem
}
</style>