<template>
  <router-link :to="`/${currentLocale}`">
    <!-- <svg width="72" height="46" viewBox="0 0 72 46" class="main-logo" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.1335 9.89844H15.0283L23.4939 25.5453H19.4483L17.8425 22.5963H9.30356L10.9133 19.6216H16.2327L12.5693 12.8694L5.71352 25.5453H1.66797L10.1335 9.89844Z" fill="white"/>
            <path d="M24.7562 24.8227C24.0845 24.3372 23.7448 23.6255 23.7293 22.6875V20.8991H27.1804V21.348C27.2267 21.8115 27.3387 22.1218 27.5124 22.286C27.6861 22.4503 27.9447 22.5452 28.2922 22.578H37.9621C38.2787 22.578 38.5141 22.5525 38.6724 22.5014C38.8307 22.4503 38.9581 22.3225 39.0507 22.1218C39.1434 21.921 39.1935 21.5962 39.1935 21.1509V20.6363C39.1935 20.1874 39.1472 19.8662 39.0507 19.6654C38.9542 19.4647 38.8307 19.3369 38.6724 19.2858C38.5141 19.2347 38.2787 19.2092 37.9621 19.2092L27.1341 19.2311C26.5512 19.2311 26.003 19.1034 25.4896 18.8515C24.9762 18.5997 24.5593 18.2347 24.235 17.7566C23.9108 17.2784 23.7409 16.72 23.7255 16.0813V13.0628C23.7409 12.4205 23.9108 11.862 24.235 11.3876C24.5593 10.9094 24.9762 10.5445 25.4896 10.2926C26.003 10.0408 26.5512 9.91303 27.1341 9.91303H39.1897C40.1046 9.91303 40.8843 10.1357 41.529 10.5846C42.1737 11.0335 42.4979 11.7927 42.4979 12.8658V14.5629H39.1897V14.4279C39.1897 13.9498 39.1472 13.5994 39.0584 13.3767C38.9735 13.1541 38.85 13.0154 38.6917 12.9643C38.5334 12.9132 38.2902 12.8877 37.9583 12.8877H28.2922C27.8019 12.9169 27.497 13.0738 27.3696 13.3585C27.2422 13.6432 27.1804 14.0447 27.1804 14.5666C27.1804 15.0885 27.2383 15.49 27.358 15.7747C27.4777 16.0594 27.7633 16.2163 28.2227 16.2455H39.1897C40.1046 16.2455 40.8843 16.4682 41.529 16.9171C42.1737 17.366 42.4979 18.1252 42.4979 19.1982V22.6182C42.4979 23.6912 42.1737 24.4504 41.529 24.8993C40.8843 25.3482 40.1007 25.5709 39.1897 25.5709H27.1804C26.2346 25.549 25.424 25.3045 24.7562 24.8227Z" fill="white"/>
            <path d="M43.9185 9.89844H47.3927V25.5453H43.9185V9.89844Z" fill="white"/>
            <path d="M57.0395 9.89844H61.9343L70.3999 25.5453H66.3582L64.7523 22.5963H56.2134L57.8232 19.6216H63.1426L59.4792 12.8694L52.6234 25.5453H48.5817L57.0395 9.89844Z" fill="white"/>
            <path d="M11.1052 35.9212C10.7628 35.6751 10.5858 35.3131 10.5781 34.8354V33.9233H12.3441V34.1513C12.3672 34.3865 12.4249 34.5458 12.5134 34.6291C12.6019 34.7123 12.7366 34.763 12.9136 34.7775H17.8616C18.0232 34.7775 18.1424 34.763 18.2232 34.7376C18.304 34.7123 18.3694 34.6472 18.4156 34.5458C18.4656 34.4445 18.4887 34.278 18.4887 34.0499V33.7893C18.4887 33.5613 18.4656 33.3984 18.4156 33.2935C18.3656 33.1921 18.304 33.127 18.2232 33.1016C18.1424 33.0763 18.0232 33.0618 17.8616 33.0618L12.321 33.0727C12.0209 33.0727 11.7439 33.0075 11.4784 32.8808C11.2129 32.7542 11.0013 32.566 10.8359 32.3234C10.6704 32.0809 10.5819 31.795 10.5742 31.4693V29.931C10.5819 29.6052 10.6704 29.3193 10.8359 29.0768C11.0013 28.8343 11.2168 28.6497 11.4784 28.5194C11.74 28.3891 12.0209 28.3275 12.321 28.3275H18.4926C18.962 28.3275 19.3583 28.4398 19.6892 28.6678C20.02 28.8958 20.1855 29.2831 20.1855 29.8296V30.6947H18.4926V30.6259C18.4926 30.3834 18.4695 30.2061 18.4271 30.0902C18.3848 29.9744 18.3194 29.9056 18.2386 29.8803C18.1578 29.855 18.0347 29.8405 17.8654 29.8405H12.9174C12.6673 29.855 12.5096 29.9346 12.4441 30.0794C12.3787 30.2242 12.348 30.4268 12.348 30.6947C12.348 30.9625 12.3787 31.1652 12.4403 31.31C12.5019 31.4548 12.6481 31.5344 12.8828 31.5489H18.4964C18.9658 31.5489 19.3621 31.6611 19.693 31.8891C20.0239 32.1171 20.1893 32.5044 20.1893 33.051V34.7919C20.1893 35.3385 20.0239 35.7258 19.693 35.9538C19.3621 36.1818 18.962 36.294 18.4964 36.294H12.348C11.8632 36.2904 11.4515 36.1673 11.1052 35.9212Z" fill="#01C3F7"/>
            <path d="M20.9127 30.6911H22.6787V32.3741H28.0269C28.3078 32.3741 28.5001 32.2909 28.6002 32.1244C28.7002 31.9579 28.7502 31.7443 28.7502 31.4874V30.6802C28.7656 30.1192 28.5425 29.8369 28.073 29.8369H20.9127V28.3239H28.5963C28.9041 28.3239 29.2042 28.3891 29.4967 28.5158C29.7891 28.6461 30.0315 28.8343 30.2239 29.0804C30.4124 29.3265 30.5124 29.6089 30.5201 29.9274V31.9868C30.5124 32.3452 30.4201 32.6673 30.2431 32.9532C30.0661 33.2392 29.8276 33.4708 29.5274 33.6373C29.2273 33.8038 28.8887 33.8871 28.5117 33.8871H22.6826V36.2904H20.9165V30.6911H20.9127Z" fill="#01C3F7"/>
            <path d="M31.7744 35.9212C31.432 35.6751 31.255 35.3131 31.2473 34.8354V30.6911H33.0133V34.1513C33.0364 34.3866 33.0941 34.5458 33.1826 34.6291C33.2711 34.7123 33.402 34.763 33.5712 34.7775H38.5308C38.6924 34.7775 38.8117 34.763 38.8925 34.7376C38.9733 34.7123 39.0387 34.6472 39.0848 34.5458C39.1349 34.4445 39.1579 34.278 39.1579 34.0499V30.6223C39.1579 30.3798 39.1349 30.2024 39.0925 30.0866C39.0502 29.9708 38.9848 29.902 38.9002 29.8767C38.8155 29.8514 38.6924 29.8369 38.5308 29.8369H31.2473C31.2781 29.3736 31.4666 29.0044 31.8052 28.7329C32.1438 28.4615 32.5401 28.3239 32.9903 28.3239H39.1502C39.6273 28.3239 40.0275 28.4361 40.3545 28.6642C40.6816 28.8922 40.8432 29.2795 40.8432 29.826V34.7883C40.8432 35.3349 40.6816 35.7221 40.3545 35.9502C40.0275 36.1782 39.6273 36.2904 39.1502 36.2904H33.0018C32.5247 36.2904 32.1169 36.1673 31.7744 35.9212Z" fill="#01C3F7"/>
            <path d="M41.5704 36.2904V30.6911H43.3364V32.3741H48.6846C48.9654 32.3741 49.1578 32.2909 49.2579 32.1244C49.3579 31.9579 49.4079 31.7443 49.4079 31.4874V30.6802C49.4233 30.1192 49.2002 29.8369 48.7307 29.8369H41.5704V28.3239H49.254C49.5618 28.3239 49.8619 28.3891 50.1544 28.5158C50.4468 28.6461 50.6892 28.8343 50.8815 29.0804C51.0701 29.3265 51.1701 29.6089 51.1778 29.9274V32.1352C51.1624 32.6275 51.0047 33.0437 50.7046 33.3803C50.4044 33.717 50.0081 33.8871 49.508 33.8871L51.1778 36.2904H49.1578L47.4995 33.8871H43.3364V36.2904H41.5704Z" fill="#01C3F7"/>
            <path d="M51.9012 28.3203H61.497V29.9961H51.9012V28.3203ZM55.8219 30.8503H57.5879V36.2904H55.8219V30.8503Z" fill="#01C3F7"/>
        </svg> -->
    <svg
      width="72"
      height="46"
      viewBox="0 0 72 46"
      class="main-logo"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_22199_6708)">
        <path
          d="M9.79942 9.64072H14.6659L23.0821 25.3165H19.0594L17.463 22.361H8.97219L10.5713 19.3806H15.8611L12.2175 12.6156L5.406 25.3165H1.3833L9.79942 9.64072Z"
          fill="white"
        />
        <path
          d="M24.3381 24.5921C23.6714 24.1045 23.3338 23.3939 23.3172 22.4519V20.6614H26.7479V21.1104C26.7949 21.5759 26.9056 21.8844 27.0771 22.0497C27.2486 22.215 27.5059 22.3086 27.8517 22.3417H37.4658C37.7812 22.3417 38.0136 22.3169 38.1713 22.2645C38.329 22.2122 38.4563 22.0855 38.5476 21.8844C38.6389 21.6834 38.6887 21.3583 38.6887 20.9121V20.397C38.6887 19.948 38.6416 19.6257 38.5476 19.4247C38.4507 19.2236 38.329 19.0969 38.1713 19.0445C38.0136 18.9922 37.7784 18.9674 37.4658 18.9674L26.7008 18.9895C26.1226 18.9895 25.5776 18.8628 25.0657 18.6093C24.5539 18.3559 24.1417 17.9923 23.818 17.5131C23.4943 17.0338 23.3255 16.4746 23.3117 15.8356V12.8111C23.3283 12.1666 23.4971 11.6074 23.818 11.1337C24.1417 10.6544 24.5539 10.288 25.0657 10.0374C25.5776 9.78395 26.1198 9.65724 26.7008 9.65724H38.6859C39.5961 9.65724 40.3708 9.88036 41.0127 10.3293C41.6545 10.7783 41.9754 11.5386 41.9754 12.6156V14.3151H38.6859V14.1801C38.6859 13.7008 38.6444 13.351 38.5559 13.1279C38.4701 12.9048 38.3484 12.7671 38.1907 12.7147C38.033 12.6624 37.7923 12.6376 37.4603 12.6376H27.849C27.3621 12.6679 27.0577 12.8249 26.9305 13.1086C26.8032 13.3951 26.7423 13.7972 26.7423 14.3178C26.7423 14.8384 26.8004 15.2434 26.9194 15.5271C27.0384 15.8135 27.3233 15.9705 27.7798 15.9981H38.6831C39.5934 15.9981 40.368 16.2212 41.0099 16.6702C41.6517 17.1192 41.9727 17.8794 41.9727 18.9564V22.383C41.9727 23.4572 41.649 24.2175 41.0099 24.6692C40.368 25.1182 39.5906 25.3413 38.6831 25.3413H26.7423C25.8017 25.3193 24.9966 25.0741 24.3326 24.5921H24.3381Z"
          fill="white"
        />
        <path
          d="M43.3892 9.64072H46.8447V25.3165H43.3892V9.64072Z"
          fill="white"
        />
        <path
          d="M56.4367 9.64072H61.3032L69.7193 25.3165H65.6994L64.103 22.361H55.615L57.2141 19.3806H62.5039L58.8602 12.6156L52.0432 25.3138H48.0261L56.4367 9.64072Z"
          fill="white"
        />
        <path
          d="M5.62185 35.7092C5.28156 35.4613 5.10449 35.1005 5.09896 34.6212V33.7067H6.85577V33.9353C6.87791 34.1722 6.93601 34.3292 7.02454 34.4146C7.11307 34.4972 7.24587 34.5496 7.42294 34.5633H12.342C12.5025 34.5633 12.6215 34.5496 12.7017 34.5248C12.7819 34.5 12.8483 34.4339 12.8926 34.332C12.9424 34.23 12.9645 34.0648 12.9645 33.8362V33.5745C12.9645 33.3459 12.9424 33.1833 12.8926 33.0787C12.8428 32.9768 12.7819 32.9134 12.7017 32.8859C12.6215 32.8611 12.5025 32.8473 12.342 32.8473L6.83364 32.8583C6.53484 32.8583 6.26095 32.7922 5.99535 32.6655C5.73252 32.5388 5.52225 32.3515 5.35626 32.1063C5.19302 31.8639 5.10449 31.5775 5.09619 31.2497V29.7072C5.10449 29.3821 5.19302 29.0957 5.35626 28.8505C5.51949 28.6081 5.73529 28.4236 5.99535 28.2914C6.25541 28.1619 6.53484 28.0985 6.83364 28.0985H12.97C13.4376 28.0985 13.8305 28.2115 14.1597 28.4401C14.4889 28.6687 14.6522 29.0571 14.6522 29.6053V30.4729H12.97V30.4041C12.97 30.1617 12.9479 29.9826 12.9036 29.8669C12.8594 29.7512 12.7958 29.6824 12.7155 29.6576C12.6353 29.6328 12.5136 29.6163 12.3448 29.6163H7.4257C7.1767 29.63 7.01901 29.7099 6.95537 29.8559C6.88897 30.0019 6.85854 30.203 6.85854 30.4729C6.85854 30.7429 6.88897 30.9439 6.94984 31.0899C7.01071 31.2359 7.15734 31.3158 7.38974 31.3296H12.97C13.4376 31.3296 13.8305 31.4425 14.1597 31.6711C14.4889 31.8997 14.6522 32.2881 14.6522 32.8363V34.5799C14.6522 35.128 14.4889 35.5164 14.1597 35.745C13.8305 35.9736 13.4321 36.0866 12.97 36.0866H6.85577C6.37438 36.0838 5.96492 35.9599 5.61909 35.712L5.62185 35.7092Z"
          fill="#01C3F7"
        />
        <path
          d="M56.9762 35.7092C56.6359 35.4613 56.4589 35.1005 56.4533 34.6212V33.7067H58.2101V33.9353C58.2323 34.1722 58.2904 34.3292 58.3789 34.4146C58.4674 34.4972 58.6002 34.5496 58.7773 34.5633H63.6964C63.8569 34.5633 63.9758 34.5496 64.0561 34.5248C64.1363 34.5 64.2027 34.4339 64.247 34.332C64.2968 34.23 64.3189 34.0648 64.3189 33.8362V33.5745C64.3189 33.3459 64.2968 33.1833 64.247 33.0787C64.1972 32.9768 64.1363 32.9134 64.0561 32.8859C63.9758 32.8611 63.8569 32.8473 63.6964 32.8473L58.188 32.8583C57.8892 32.8583 57.6153 32.7922 57.3497 32.6655C57.0869 32.5388 56.8766 32.3515 56.7106 32.1063C56.5474 31.8639 56.4589 31.5775 56.4506 31.2497V29.7072C56.4589 29.3821 56.5474 29.0957 56.7106 28.8505C56.8739 28.6081 57.0897 28.4236 57.3497 28.2914C57.6098 28.1619 57.8892 28.0985 58.188 28.0985H64.3244C64.792 28.0985 65.1848 28.2115 65.5141 28.4401C65.8433 28.6687 66.0065 29.0571 66.0065 29.6053V30.4729H64.3244V30.4041C64.3244 30.1617 64.3023 29.9826 64.258 29.8669C64.2138 29.7512 64.1501 29.6824 64.0699 29.6576C63.9897 29.6328 63.8679 29.6163 63.6992 29.6163H58.7801C58.5311 29.63 58.3734 29.7099 58.3097 29.8559C58.2433 30.0019 58.2129 30.203 58.2129 30.4729C58.2129 30.7429 58.2433 30.9439 58.3042 31.0899C58.3651 31.2359 58.5117 31.3158 58.7441 31.3296H64.3244C64.792 31.3296 65.1848 31.4425 65.5141 31.6711C65.8433 31.8997 66.0065 32.2881 66.0065 32.8363V34.5799C66.0065 35.128 65.8433 35.5164 65.5141 35.745C65.1848 35.9736 64.7864 36.0866 64.3244 36.0866H58.2101C57.7287 36.0838 57.3193 35.9599 56.9735 35.712L56.9762 35.7092Z"
          fill="#01C3F7"
        />
        <path
          d="M15.3715 30.4702H17.1283V32.1559H22.4458C22.7252 32.1559 22.9161 32.0733 23.0157 31.9053C23.1153 31.74 23.1651 31.5251 23.1651 31.2662V30.4591C23.1817 29.8972 22.9576 29.6135 22.4928 29.6135H15.3743V28.0985H23.0129C23.32 28.0985 23.6161 28.1647 23.9093 28.2914C24.1998 28.4208 24.4405 28.6109 24.6314 28.856C24.8196 29.1039 24.9192 29.3849 24.9247 29.7044V31.7675C24.9164 32.1256 24.8251 32.4506 24.648 32.7371C24.471 33.0236 24.2358 33.255 23.937 33.423C23.6382 33.5882 23.3007 33.6736 22.9272 33.6736H17.1311V36.0811H15.3743V30.4702H15.3715Z"
          fill="#01C3F7"
        />
        <path
          d="M26.1725 35.7092C25.8322 35.4613 25.6551 35.1005 25.6468 34.6212V30.4702H27.4036V33.9381C27.4257 34.175 27.4838 34.332 27.5724 34.4173C27.6609 34.5 27.7909 34.5523 27.9597 34.5661H32.8899C33.0503 34.5661 33.1693 34.5523 33.2495 34.5275C33.3298 34.5027 33.3934 34.4366 33.4404 34.3347C33.4902 34.2328 33.5124 34.0675 33.5124 33.8389V30.4041C33.5124 30.1617 33.4902 29.9826 33.4487 29.8669C33.4072 29.7512 33.3408 29.6824 33.2578 29.6576C33.1748 29.6328 33.0503 29.619 32.8899 29.619H25.6496C25.68 29.1563 25.8681 28.7844 26.2029 28.5145C26.5404 28.2418 26.9333 28.1041 27.3815 28.1041H33.5068C33.9799 28.1041 34.3783 28.217 34.7048 28.4456C35.0285 28.6742 35.1917 29.0626 35.1917 29.6108V34.5826C35.1917 35.1308 35.0312 35.5191 34.7048 35.745C34.3783 35.9736 33.9827 36.0866 33.5068 36.0866H27.3925C26.9194 36.0866 26.5128 35.9626 26.1725 35.7175V35.7092Z"
          fill="#01C3F7"
        />
        <path
          d="M35.911 36.0811V30.4702H37.6678V32.1559H42.9853C43.2648 32.1559 43.4557 32.0733 43.5553 31.9053C43.6549 31.74 43.7047 31.5251 43.7047 31.2662V30.4591C43.7213 29.8972 43.4972 29.6135 43.0324 29.6135H35.9138V28.0985H43.5525C43.8596 28.0985 44.1556 28.1647 44.4461 28.2914C44.7366 28.4208 44.9773 28.6109 45.1682 28.856C45.3563 29.1039 45.4559 29.3849 45.4615 29.7044V31.9163C45.4476 32.4093 45.2899 32.8253 44.9911 33.1641C44.6923 33.5001 44.2995 33.6709 43.8015 33.6709L45.4615 36.0783H43.4529L41.804 33.6709H37.6651V36.0783H35.9083L35.911 36.0811Z"
          fill="#01C3F7"
        />
        <path
          d="M46.1836 28.0958H55.7229V29.776H46.1836V28.0958ZM50.079 30.6299H51.8358V36.0783H50.079V30.6299Z"
          fill="#01C3F7"
        />
      </g>
      <defs>
        <clipPath id="clip0_22199_6708">
          <rect
            width="68.336"
            height="26.4431"
            fill="white"
            transform="translate(1.3833 9.64072)"
          />
        </clipPath>
      </defs>
    </svg>
  </router-link>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  computed: {
    ...mapGetters(["currentLocale"]),
  },
};
</script>

<style>
.main-logo {
  width: inherit;
  height: inherit;
}
</style>
