<template>
    <div v-if="pageFromSkeleton ==='notificationPage'" class="noti-skeleton-wrapper" v-for="(o,index) in 7">
		<div class="skeleton-effect__1 skeleton-square__1 mr-1rem"></div>
		<div class="flex-1 mr-2rem">
			<div class="skeleton-effect__1  skeleton-w25 mb-xs"></div>
			<div class="skeleton-effect__1  skeleton-w100 mb-xs"></div>
			<div class="skeleton-effect__1  skeleton-w100 mb-xs"></div>
		</div>
		<div class="skeleton-effect__1 skeleton-square__1"></div>
	</div>
    <div v-else class="noti-skeleton-wrapper mr-1rem" v-for="(o,index) in 5">
        <div class="skeleton-effect__1 skeleton-square__1" style="margin-right:1.75rem"></div>
		<div class="flex-1 mr-2rem">
			<div class="skeleton-effect__1  skeleton-w35 mb-xs"></div>
			<div class="skeleton-effect__1  skeleton-w100 mb-xs"></div>
			<div class="skeleton-effect__1  skeleton-w20 mb-xs"></div>
		</div>
		<div class="skeleton-effect__1 skeleton-square__1"></div>
    </div>
</template>
<script>
export default {
	props: {
		pageFromSkeleton: {
			type: String,
		},
	},
}
</script>
<style scoped>
    .noti-skeleton-wrapper{
        display: flex;
		align-items: center;
        margin-bottom:1.5rem;
		padding: 0 1rem;
    }
</style>