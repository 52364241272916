import config from "@/js/config.js";
import footerLinks from "@/js/seoArticles/footer.js";

const projectName = config.projectName;
const description = `AsiaSports is a current hub where all the sports fans gather for newsfeed. Every aspect of the tournament will be made accessible at all times. When a football match is held, the live scores will be updated in real-time as it progresses.`;
const footerLinksPageMeta = {};
Object.values(footerLinks).forEach((arr) => {
  if (arr.length > 0) {
    arr.forEach((article) => {
      footerLinksPageMeta[article.id] = (route) => ({
        title: article.titleTag,
        description: article.metaDescription,
      });
    });
  }
});

export default {
  home: (route) => ({
    title: `足球实时赛果、实时比分、赛程、精彩镜头 - ${projectName}`,
    description: description,
  }),
  highlights: (route) => ({
    title: `足球精彩镜头、足球实时赛果、足球精彩镜头 - ${projectName}`,
    description: description,
  }),
  news: (route) => ({
    title: `在线观看足球、足球精彩镜头、直播 - ${projectName}`,
    description: description,
  }),
  event: (route) => ({
    title: `足球比赛、足球比赛和活动 - ${projectName}`,
    description: description,
  }),
  account: (route) => ({
    title: `我的个人资料 - ${projectName}`,
    description: description,
  }),
  player: (route) => ({
    title: `${route.params.playerName
      .split("-")
      .join(" ")} 个人资料 - ${projectName}`,
    description: description,
  }),
  matchDetail: (route) => ({
    title: `${route.params.opponent
      .split("-")
      .join(" ")} 精彩镜头 - ${projectName}`,
    description: description,
  }),
  highlightsDetail: (route) => ({
    title: `${route.params.opponent
      .split("-")
      .join(" ")} 精彩镜头 - ${projectName}`,
    description: description,
  }),
  ...footerLinksPageMeta,
};
