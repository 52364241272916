<template>
  <svg v-if="pathName === 'home' && !selected" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.99991 1.33334C6.23185 1.33334 4.53612 2.03582 3.28607 3.28607C2.03585 4.53628 1.33334 6.23198 1.33334 7.99991C1.33334 9.76811 2.03582 11.4637 3.28607 12.7141C4.53628 13.9643 6.23198 14.6667 7.99991 14.6667C9.76811 14.6667 11.4637 13.9643 12.7141 12.7141C13.9643 11.4639 14.6667 9.76817 14.6667 7.99991C14.6667 6.82975 14.3587 5.68006 13.7735 4.66676C13.1884 3.65333 12.3468 2.81172 11.3334 2.2265C10.32 1.6414 9.17027 1.33334 7.99991 1.33334ZM10.7618 10.9905L9.90473 9.81904L10.6142 7.65241L11.9856 7.20483L13.7143 8.43809C13.6446 9.34254 13.3606 10.2175 12.8857 10.9905H10.7618ZM5.23808 10.9905H3.13806C2.6632 10.2175 2.37914 9.34254 2.30951 8.43809L4.00946 7.20471L5.38085 7.65228L6.09526 9.81904L5.23808 10.9905ZM3.36191 6.49509L2.34281 7.23804C2.43406 6.55481 2.64686 5.89355 2.97138 5.28558L3.36191 6.49509ZM6.96181 9.42842L6.31892 7.45221L7.99988 6.23314L9.68084 7.45221L9.03806 9.42842H6.96181ZM12.6381 6.49509L13.0286 5.28558H13.0285C13.353 5.89355 13.5659 6.55477 13.657 7.23804L12.6381 6.49509ZM12.3476 4.29979L11.6999 6.29498L10.3237 6.74256L8.47608 5.4046V3.97603L10.176 2.73797C11.0133 3.07946 11.7574 3.61478 12.3476 4.29991L12.3476 4.29979ZM9.01411 2.39497L7.99981 3.1237L6.97608 2.38075C7.31406 2.32017 7.65655 2.28824 7.99981 2.28561C8.3432 2.28824 8.68568 2.32017 9.02366 2.38075L9.01411 2.39497ZM5.81406 2.73308L7.52369 3.95704V5.38561L5.67606 6.7237L4.29987 6.27613L3.65219 4.29991C4.24015 3.60786 4.98449 3.06588 5.82359 2.71886L5.81406 2.73308ZM3.87608 11.9426H5.14264L5.53316 13.1426V13.1427C4.91312 12.8456 4.35168 12.4391 3.87608 11.9426V11.9426ZM6.65709 13.5474L6.00941 11.5522L6.85706 10.3808H9.14284L9.9905 11.5522L9.34282 13.5474H9.3427C8.46122 13.7695 7.53843 13.7695 6.65696 13.5474H6.65709ZM10.4665 13.1427L10.8569 11.9426H12.114C11.644 12.4372 11.0892 12.8437 10.4759 13.1427H10.4665Z" fill="white"/>
  </svg>
  <svg v-else-if="pathName === 'home' && selected" class="selected" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.99994 1.33334C6.23188 1.33334 4.53615 2.03582 3.2861 3.28607C2.03588 4.53628 1.33337 6.23198 1.33337 7.99991C1.33337 9.76811 2.03585 11.4637 3.2861 12.7141C4.53631 13.9643 6.23201 14.6667 7.99994 14.6667C9.76814 14.6667 11.4637 13.9643 12.7141 12.7141C13.9643 11.4639 14.6667 9.76817 14.6667 7.99991C14.6667 6.82975 14.3588 5.68006 13.7736 4.66676C13.1885 3.65333 12.3469 2.81172 11.3334 2.2265C10.32 1.6414 9.1703 1.33334 7.99994 1.33334ZM10.7618 10.9905L9.90476 9.81904L10.6143 7.65241L11.9857 7.20483L13.7143 8.43809C13.6447 9.34254 13.3606 10.2175 12.8857 10.9905H10.7618ZM12.3475 4.32382L11.6999 6.32378L10.3237 6.76659L8.47616 5.40476V3.97619L10.1809 2.73813C11.0152 3.08226 11.7573 3.61719 12.3476 4.30008L12.3475 4.32382ZM5.81893 2.71902L7.52377 3.95717V5.38574L5.67613 6.72383L4.29994 6.28102L3.65227 4.30004C4.24048 3.61024 4.9827 3.06865 5.8189 2.71899L5.81893 2.71902ZM3.13799 10.9906C2.66313 10.2176 2.37908 9.3427 2.30945 8.43825L4.00939 7.20487L5.38078 7.65244L6.0952 9.8192L5.23798 10.9906H3.13799ZM6.65713 13.5478L6.00945 11.5526L6.85723 10.3812H9.14301L9.99067 11.5526L9.34299 13.5478H9.34286C8.46139 13.7699 7.5386 13.7699 6.65713 13.5478H6.65713Z" fill="black"/>
  </svg>

  <svg v-if="pathName === 'basketball' && !selected" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M13.1011 6.10107C13.1011 3.28375 10.8173 1 7.99999 1C5.18267 1 2.89893 3.28375 2.89893 6.10107C2.89893 6.71464 3.00725 7.30291 3.20581 7.84778H4.31148C4.25993 7.73896 4.21308 7.62763 4.17113 7.51406C5.06417 7.15417 5.83642 6.54769 6.39775 5.76542L7.30319 6.28777L6.40259 7.84778H7.58068L8.18669 6.79787L9.09162 7.32124C9.01377 7.49339 8.94585 7.66919 8.88799 7.84778H9.97541C9.97735 7.84315 9.97929 7.83852 9.98125 7.8339L10.0053 7.84778H12.7942C12.9927 7.30291 13.1011 6.71464 13.1011 6.10107ZM11.9299 5.76031L12.0615 5.6991C12.1293 6.37563 12.0265 7.05787 11.7625 7.68444L10.4914 6.95039C10.8717 6.44717 11.3643 6.03967 11.9299 5.76031ZM3.93852 6.50354C3.92577 6.37142 3.91914 6.23675 3.91914 6.10107C3.91842 5.55748 4.02666 5.01926 4.23745 4.51821L5.50863 5.25225C5.09842 5.79436 4.5585 6.22464 3.93852 6.50354ZM8.6968 5.91437L10.4664 2.84965L10.5954 2.95218C11.1528 3.41149 11.5785 4.01026 11.8294 4.68756C10.936 5.04767 10.1636 5.65452 9.60224 6.43723L8.6968 5.91437ZM7.30778 2.07888C7.53273 2.04062 7.76381 2.02021 7.99999 2.02021C8.54358 2.0195 9.0818 2.12773 9.58285 2.33852L7.81329 5.40426L6.90785 4.8814C7.30486 4.00424 7.44358 3.03207 7.30778 2.07888ZM6.22074 2.42728L6.31817 2.38239C6.3863 3.05897 6.28334 3.74182 6.01874 4.36823L4.74857 3.6347C5.13769 3.12222 5.64202 2.70858 6.22074 2.42728Z" fill="white"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M3.22051 7.48791H2.00146C1.76483 7.48791 1.573 7.65607 1.573 7.86352C1.573 8.07096 1.76483 8.23912 2.00146 8.23912H2.9598L3.66801 9.53944C4.44379 11.1272 4.40832 12.8457 3.66058 14.4835C3.57266 14.6761 3.67948 14.8947 3.89917 14.9718C4.11886 15.0489 4.36823 14.9552 4.45616 14.7626C4.624 14.395 4.7597 14.0223 4.86199 13.6468H7.56452V14.6244C7.56452 14.8318 7.75635 15 7.99298 15C8.22961 15 8.42144 14.8318 8.42144 14.6244V13.6468H11.1224C11.225 14.0225 11.3613 14.3954 11.5301 14.7633C11.6184 14.9557 11.868 15.049 12.0875 14.9715C12.307 14.8941 12.4134 14.6753 12.3251 14.4829C11.5738 12.8459 11.5419 11.1276 12.318 9.53943L13.0262 8.23912H13.9984C14.2351 8.23912 14.4269 8.07096 14.4269 7.86352C14.4269 7.65607 14.2351 7.48791 13.9984 7.48791H12.7654C12.7593 7.48779 12.7531 7.48779 12.747 7.48791H3.23895C3.23282 7.48779 3.22667 7.48779 3.22051 7.48791ZM7.56452 8.23912H3.90939L4.44502 9.22259C4.44746 9.22706 4.4498 9.23158 4.45203 9.23613C4.60629 9.55085 4.73194 9.86985 4.82979 10.1917H7.56452V8.23912ZM8.42144 10.1917V8.23912H12.0766L11.5409 9.22259C11.5385 9.22706 11.5362 9.23158 11.5339 9.23613C11.3797 9.55082 11.2539 9.86983 11.1559 10.1917H8.42144ZM7.56452 10.9429H5.00728C5.11737 11.5905 5.11989 12.2451 5.02123 12.8956H7.56452V10.9429ZM8.42144 12.8956V10.9429H10.9778C10.8673 11.5904 10.8642 12.245 10.9629 12.8956H8.42144Z" fill="white"/>
  </svg>
  <svg v-else-if="pathName === 'basketball' && selected" class="selected" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.18482 6.73954L9.08115 7.25714C8.54606 8.44232 8.48979 9.78845 8.92411 11.0142C8.61938 11.0712 8.31002 11.0997 8 11.0995C7.28446 11.1006 6.57691 10.9491 5.92456 10.6551L8.18482 6.73954ZM9.96132 7.76565L12.0989 8.99981C11.5419 9.77284 10.7783 10.3732 9.89568 10.7319C9.55951 9.82418 9.56094 8.82588 9.89972 7.91916L9.96132 7.76565ZM6.41388 5.71747L7.31021 6.23457L5.04994 10.1481C4.21386 9.54592 3.58141 8.70296 3.23709 7.73182C4.45728 7.50654 5.55143 6.8389 6.30985 5.85685L6.41388 5.71747ZM13.0018 5.35086C13.0336 5.57962 13.0497 5.81291 13.0497 6.04975C13.0509 6.76529 12.8994 7.47285 12.6054 8.12519L10.4663 6.89053C11.0685 6.09566 11.944 5.55216 12.9235 5.365L13.0018 5.35086ZM3.39514 3.97531L5.53371 5.20998C4.9148 6.02672 4.00831 6.57737 2.99823 6.75015C2.96623 6.51805 2.95021 6.28404 2.95026 6.04975C2.94937 5.33423 3.10086 4.62673 3.39464 3.9743M10.9496 1.95037C11.7856 2.55256 12.4181 3.39553 12.7624 4.36667C11.542 4.5924 10.4477 5.26036 9.68914 6.24265L9.58562 6.38202L8.68929 5.86442L10.9496 1.95037ZM8 1C8.71552 0.999117 9.42302 1.1506 10.0754 1.44438L7.81518 5.35995L6.91885 4.84286C7.45422 3.65739 7.51049 2.31084 7.0759 1.08484C7.38065 1.02803 7.69001 0.999631 8 1ZM6.10483 1.36864C6.4405 2.27647 6.4387 3.27471 6.09979 4.18134L6.03919 4.33435L3.90113 3.09969C4.43652 2.35686 5.16316 1.77296 6.00384 1.41004L6.10483 1.36864Z" fill="black"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M3.22058 7.48791H2.00152C1.76489 7.48791 1.57306 7.65607 1.57306 7.86352C1.57306 8.07096 1.76489 8.23912 2.00152 8.23912H2.95987L3.66807 9.53944C4.44385 11.1272 4.40838 12.8457 3.66064 14.4835C3.57272 14.6761 3.67954 14.8947 3.89923 14.9718C4.11892 15.0489 4.3683 14.9552 4.45622 14.7626C4.62406 14.395 4.75976 14.0223 4.86206 13.6468H7.56458V14.6244C7.56458 14.8318 7.75641 15 7.99304 15C8.22968 15 8.42151 14.8318 8.42151 14.6244V13.6468H11.1224C11.225 14.0225 11.3613 14.3954 11.5302 14.7633C11.6185 14.9557 11.868 15.049 12.0876 14.9715C12.3071 14.8941 12.4135 14.6753 12.3252 14.4829C11.5739 12.8459 11.542 11.1276 12.318 9.53943L13.0262 8.23912H13.9985C14.2351 8.23912 14.427 8.07096 14.427 7.86352C14.427 7.65607 14.2351 7.48791 13.9985 7.48791H12.7655C12.7594 7.48779 12.7532 7.48779 12.7471 7.48791H3.23901C3.23288 7.48779 3.22673 7.48779 3.22058 7.48791Z" fill="black"/>
  </svg>

  <svg v-if="pathName === 'news' && !selected" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12.6667 3.33333V12.6667H3.33333V3.33333H12.6667ZM13.4 2H2.6C2.26667 2 2 2.26667 2 2.6V13.4C2 13.6667 2.26667 14 2.6 14H13.4C13.6667 14 14 13.6667 14 13.4V2.6C14 2.26667 13.6667 2 13.4 2ZM7.33333 4.66667H11.3333V6H7.33333V4.66667ZM4.66667 7.33333H11.3333V8.66667H4.66667V7.33333ZM4.66667 10H11.3333V11.3333H4.66667V10ZM4.66667 4.66667H6V6H4.66667V4.66667Z" fill="white"/>
  </svg>
  <svg v-else-if="pathName === 'news' && selected" class="selected" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M2.6 2H13.4C13.6667 2 14 2.26667 14 2.6V13.4C14 13.6667 13.6667 14 13.4 14H2.6C2.26667 14 2 13.6667 2 13.4V2.6C2 2.26667 2.26667 2 2.6 2ZM7.33333 4.66667H11.3333V6H7.33333V4.66667ZM4.66667 7.33333H11.3333V8.66667H4.66667V7.33333ZM11.3333 10H4.66667V11.3333H11.3333V10ZM4.66667 4.66667H6V6H4.66667V4.66667Z" fill="black"/>
  </svg> 

</template>

<script>
export default {
  props: {
    pathName: {
      type: String,
      required: true,
    },
    selected: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped>
svg {
  margin-right: 0.25rem;
}
.selected rect,
.selected path {
  fill: #000000;
}
</style>
