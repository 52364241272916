<template>
  <!-- App Redirect Hover -->
  <div
    class="app-redirect"
    @mouseenter="showRedirect"
    @mouseleave="hideRedirect"
  >
    <div class="app-redirect__icon">
      <img src="../../../static/images/app_phone.png" alt="" />
      <div class="app-redirect__icon-bounce">
        <img src="../../../static/images/loading.gif" alt="" />
      </div>
    </div>
    <!-- <div class="redirect__text">{{ $t("AVAILABLE_STORE") }}</div> -->

    <!-- Hover Content -->
    <div v-if="redirectShow" class="modal-wrapper display-flex-center">
      <div class="modal__hover-container">
        <div class="app-redirect__hover-container" @mouseleave="hideRedirect">
          <div class="app-redirect__hover--bg">
            <img src="../../../static/images/app_eclipse.png" alt="" />
          </div>
          <div class="app-redirect__hover--wrapper">
            <div class="app-redirect__hover--content">
              <p class="title">{{ $t("DOWNLOAD") }}</p>
              <p>{{ $t("GET_REAL_TIME") }}</p>
              <div style="margin-top: 1rem">
                <p>{{ $t("AVAILABLE") }}</p>
                <div class="app-redirect__hover--link__wrapper">
                  <div class="app-redirect__hover--link">
                    <div class="app-redirect__link">
                      <template
                        v-if="
                          currentCountryObj !== null &&
                          currentCountryObj.countryCode === 'TH'
                        "
                      >
                        <div class="app-redirect__link-img">
                          <a :href="apkUrl" target="_blank"
                            ><img
                              src="../../../static/images/android.png"
                              alt=""
                            />
                          </a>
                        </div>
                        <p>{{ $t("DOWNLOAD_NOW") }}</p>
                      </template>
                      <template v-else>
                        <div class="app-redirect__link-img">
                          <a :href="androidPlayLink" target="_blank"
                            ><img
                              src="../../../static/images/google_white.png"
                              alt=""
                            />
                          </a>
                        </div>
                        <p>Google Play</p>
                      </template>
                    </div>
                    <div class="app-redirect__link">
                      <div class="app-redirect__link-img">
                        <a :href="appleStoreLink" target="_blank"
                          ><img src="../../../static/images/apple.png" alt=""
                        /></a>
                      </div>
                      <p>App Store</p>
                    </div>
                  </div>
                  <div class="app-redirect__hover--barcode">
                    <APKDownloadPageQR
                      v-if="
                        currentCountryObj !== null &&
                        currentCountryObj.countryCode === 'TH'
                      "
                    />
                    <img
                      v-else
                      src="../../../static/images/barcode.png"
                      alt=""
                    />
                    {{ $t("SCAN") }}
                  </div>
                </div>
              </div>
            </div>
            <div class="app-redirect__hover-container-image">
              <img src="../../../static/images/app_phone_model.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import config from "@/js/config.js";
import { mapGetters, mapActions } from "vuex";
import APKDownloadPageQR from "@/components/mobile/app/APKDownloadPageQR.vue";

export default {
  components: {
    APKDownloadPageQR,
  },
  data() {
    return {
      redirectShow: false,
      isShowAvailableStoreHeader: true,
      androidPlayLink: config.app.android.link,
      appleStoreLink: config.app.apple.link,
      apkUrl: null,
    };
  },
  mounted() {
    this.setApkUrl();
  },
  watch: {
    currentCountryObj: {
      deep: true,
      handler(newVal, oldVal) {
        this.setApkUrl();
      },
    },
    currentLanguageObj: {
      deep: true,
      handler(newVal, oldVal) {
        this.setApkUrl();
      },
    },
  },
  computed: {
    ...mapGetters([
      "isMobile",
      "userInfo",
      "isLogin",
      "currentCountryObj",
      "currentLanguageObj",
    ]),
  },
  methods: {
    setApkUrl() {
      if (this.currentCountryObj !== null) {
        let countryCode = this.currentCountryObj.countryCode;
        let displayLocale = this.currentLanguageObj.displayLocale;

        if (countryCode === "TH" && displayLocale === "th") {
          this.apkUrl = config.apk.thLink;
        } else if (countryCode === "TH" && displayLocale === "en") {
          this.apkUrl = config.apk.thEnLink;
        }
      }
    },
    // redirect app
    showRedirect() {
      this.redirectShow = true;
      // document.body.className = "hidden";
      window.addEventListener("scroll", () => {
        this.hideRedirect();
      });
    },
    hideRedirect() {
      this.redirectShow = false;
      // document.body.classList.remove("hidden");
    },
  },
};
</script>

<style scoped>
/* App Redirect */
.modal-wrapper {
  top: 4.5rem;
  background-color: rgb(0 0 0 / 57%);
}

.modal__hover-container {
  width: 1200px;
  position: absolute;
  top: 0;
}
.app-redirect {
  font-size: 0.625rem;
  line-height: 0.875rem;
  font-weight: 700;
  margin-right: 1rem;
  display: flex;
  align-items: center;
  position: relative;
}

.app-redirect__icon {
  width: 2.375rem;
}

.app-redirect__icon-bounce {
  width: 3.125rem;
  position: absolute;
  top: -2rem;
  left: -0.5rem;
}

.app-redirect img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.app-redirect__hover-container {
  background: #000000;
  box-shadow: 0px 16.579px 22.1053px rgba(167, 174, 186, 0.12);
  border-radius: 0.75rem;
  position: absolute;
  min-width: 29rem;
  top: 2rem;
  right: 0;
  filter: drop-shadow(-1px 0px 10px rgba(1, 195, 247, 0.8));
  padding: 1.5rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
  display: flex;
  align-items: center;
}

.app-redirect__hover--bg {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
}

.app-redirect__hover--wrapper {
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

.app-redirect__hover-container-image {
  width: 30%;
}

.app-redirect__hover--content {
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 700;
  width: 60%;
}

.app-redirect__hover--content .title {
  font-size: 1.375rem;
  line-height: 1.625rem;
}

.app-redirect__hover--link__wrapper,
.app-redirect__hover--link {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.app-redirect__hover--barcode {
  text-align: center;
  width: 4.875rem;
  display: flex;
  flex-direction: column;
}

.app-redirect__link-img {
  width: 1.75rem;
}

.app-redirect__link {
  margin-right: 0.875rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-transform: capitalize;
}
</style>
