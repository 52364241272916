<template>
  <Transition name="opacity">
        <div class="toastr-fav__wrapper" :class="position" v-if="isShowToastr">
            <div class="toastr-fav__container">
                <!-- <img v-if="toastrFav.isAddedFav" class="toastr-fav__img" src="../../../static/images/icons/icon_toastr_fav_added.png"> -->
                <i v-if="currentToastr.isAddedFav" class="toastr-fav__img">
                    <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10.8906 0C5.37063 0 0.890625 4.48 0.890625 10C0.890625 15.52 5.37063 20 10.8906 20C16.4106 20 20.8906 15.52 20.8906 10C20.8906 4.48 16.4106 0 10.8906 0ZM8.89062 15L3.89062 10L5.30062 8.59L8.89062 12.17L16.4806 4.58L17.8906 6L8.89062 15Z" fill="#5CD66C"/>
                    </svg>
                </i>
                <!-- <img v-else class="toastr-fav__img" src="../../../static/images/icons/icon_toastr_fav_removed.png"> -->
                <i v-else  class="toastr-fav__img">
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM11 15H9V13H11V15ZM11 11H9V5H11V11Z" fill="#FBBC04"/>
                    </svg>
                </i>

                <div class="toastr-fav__text">
                    <span>{{ currentToastr.msg }}&nbsp;</span>
                    <span>{{ currentToastr.isAddedFav ? $t('FAV_TOASTR_ADDED') : $t('FAV_TOASTR_REMOVED') }}</span>                  
                </div>
            </div>
        </div>
 
  </Transition>
</template>

<script>
import { mapGetters,mapActions } from 'vuex'

export default {
    data() {
        return {
            position: '',//bottom
            isShowToastr : false,
            isShowToastrContent : false,
            closeTime: 1200, //2s
            incomingToastr: [],
            currentToastr: {}
        }
    },
    watch: {
        toastrFav: {
            deep: true,
            handler(news, prev) {    
                if (news.isOpen) {
                    this.storeIncomingToastr();                    
                }
            }
        },
   },
    computed: {
        ...mapGetters([              
            'isMobile',
            'toastrFav'
       ]),
    },
    mounted() {
        this.init();
    },
    methods: {
        ...mapActions([
            "toastrFavChangeEvent"
        ]),
        
        storeIncomingToastr() {
            this.incomingToastr.push(JSON.parse(JSON.stringify(this.toastrFav)));
            this.showIncomingToastr();
        },

        showIncomingToastr() {
            if (this.incomingToastr.length > 0) {
                this.handleOpen(this.incomingToastr.shift())
            }
        },
          
        init() {
            this.position = this.isMobile ? 'bottom' : 'top';
        },
        
        handleOpen(info) {             
            this.isShowToastr = true;

            setTimeout(() => {
                this.isShowToastrContent = true;
            }, 100);   

            this.currentToastr = info

            setTimeout(() => {
               this.handleClose();
               if (this.incomingToastr.length > 0) {

               }
            },this.closeTime);
        },
        handleClose() {
            setTimeout(() => {
                this.isShowToastr = false;
            }, 0);

            let params={
                isOpen: false
            }

            this.toastrFavChangeEvent(params);
            
            this.isShowToastrContent = false;
        },
    },
}
</script>

<style>
.toastr-fav__container{
    background-color: white;
    border-radius: 2rem;
    padding: .5rem;
    font-size: .875rem;
    display: flex;
    align-items: center;
}
.toastr-fav__img{
    margin-right: 0.625rem;
    display: flex;
    align-items: center;
    justify-content: center;
}
.toastr-fav__wrapper{
    display: flex;
    justify-content: center;
    position: fixed;
    width: 90%;
    margin: 0 5%;
    z-index: 11;
}
.toastr-fav__text{
    color: black;
}

.toastr-fav__wrapper.top {
    top: 3.5rem;
}
.toastr-fav__wrapper.bottom {
    bottom: 5rem;
}
</style>