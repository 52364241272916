<template>
    <div class="noti-toastr__wrapper">
        <TransitionGroup
        tag="div"
        :css="false"
        @before-enter="onBeforeEnter"
        @enter="onEnter"
        @leave="onLeave"
        >
        <!-- <TransitionGroup name="list" tag="div" class="noti-toastr__card-container"> -->
            <div v-show="isShowBlurTop" class="noti-toastr__blur-top"></div>
            <router-link :to=formatUrl(o) target="_blank"  class="noti-toastr__card" v-for="(o, index) in list" :key="o" @click="handleMatchCardClick(o, $event)">
                <div class="noti-toastr__card-icon">
                    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M6.05 2.3798L4.62 0.949805C2.22 2.7798 0.64 5.5998 0.5 8.7998H2.5C2.65 6.1498 4.01 3.8298 6.05 2.3798ZM18.44 8.7998H20.44C20.29 5.5998 18.71 2.7798 16.32 0.949805L14.9 2.3798C16.92 3.8298 18.29 6.1498 18.44 8.7998ZM16.47 9.2998C16.47 6.2298 14.83 3.6598 11.97 2.9798V2.2998C11.97 1.4698 11.3 0.799805 10.47 0.799805C9.64 0.799805 8.97 1.4698 8.97 2.2998V2.9798C6.1 3.6598 4.47 6.2198 4.47 9.2998V14.2998L2.47 16.2998V17.2998H18.47V16.2998L16.47 14.2998V9.2998ZM10.47 20.2998C10.61 20.2998 10.74 20.2898 10.87 20.2598C11.52 20.1198 12.05 19.6798 12.31 19.0798C12.41 18.8398 12.46 18.5798 12.46 18.2998H8.46C8.47 19.3998 9.36 20.2998 10.47 20.2998Z" fill="#01C3F7"/>
                    </svg>
                </div>
                <div class="noti-toastr__card-content">
                    <p class="noti-toastr__card-content-title"><b>{{ o.title }} - </b>{{ formatContent(o) }}</p>
                    <p class="noti-toastr__card-content-time">{{ formatTime(o) }}</p>
                </div>
                <div class="noti-toastr__card-close" @click="handleClose(o, $event, false)">
                    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="12.5004" cy="12.0004" r="9.6" fill="#212B30"/>
                    <path d="M17.2992 8.16705L16.3324 7.2002L12.4992 11.0333L8.66608 7.2002L7.69922 8.16705L11.5324 12.0002L7.69922 15.8333L8.66608 16.8002L12.4992 12.9671L16.3324 16.8002L17.2992 15.8333L13.4661 12.0002L17.2992 8.16705Z" fill="#E7ECEE"/>
                    </svg>
                </div>
            </router-link>
        </TransitionGroup>
    </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import moment from 'moment';

import gsap from 'gsap'
export default {
    data() {
        return {
            // list: [1,2,3,4],
            list: [],
            isShowBlurTop: false,

            info: {
                displayTime: 5 //second in unit
            },

            contestType: [
                {
                    type: 1,
                    urlName: 'footballEventPrediction',
                },
                {
                    type: 2,
                    urlName: 'footballEventTopreferral',
                },
                {
                    type: 3,
                    urlName: 'footballEventMatchScore',
                },
                {
                    type: 4,
                    urlName: 'footballEventWorldCup',
                },
                {
                    type: 5,
                    urlName: 'footballEventTikTok',
                }
            ],
        };
    },
    computed: {
        ...mapGetters([
            "isMobile",
            "hasNewNotification",
            "newNotificationData",
            "isLogin",
            "currentLocale",
            "currentSportType"
        ]),
    },
     watch: {
        newNotificationData: {
            deep: true,
            handler(newVal, oldVal) {
                if (newVal) {
                    let params = JSON.parse(JSON.stringify(newVal)) ;
                    if (params) {
                            params.receiveDateTime = new Date();
                       
                            this.list.push(params);

                            this.verifyToShowBlur();
                        
                    }                               
                }
            }
        }
     },
    mounted() {
         this.init();
    },
    methods: {
           ...mapActions([
            "toggleNewNotification",
            "getNotificationRead",
            "getNotificationNotified"
        ]),
        onBeforeEnter(el) {
            el.style.opacity = 0
            el.style.height = 0
        },
        onEnter(el, done) {
            gsap.to(el, {
                opacity: 1,
                height: '3.5rem',
                delay: el.dataset.index * 0.15,
                onComplete: done
            })
        },
        onLeave(el, done) {
            gsap.to(el, {
                opacity: 0,
                height: 0,
                delay: el.dataset.index * 0.15,
                onComplete: done
            })
        },
        init() {
            setInterval(() => { 
                if (this.list.length > 0) {
                    this.list.forEach (x=> {
                        let currentDate = moment(new Date());
                        let endDate = moment(x.receiveDateTime);

                        let duration = moment.duration(currentDate.diff(endDate));

                        if (duration.asSeconds() >= this.info.displayTime) {
                            this.handleClose(x, null, true)
                        }
                    })
                }
            },1000)
        },
        formatTime(data) {
            let utcDate = moment.utc(data.createTime).format();

            let localDate = moment.utc(utcDate).local();

			return localDate.fromNow()
		},

		formatType(data) {
			return type[data.type]
		},
		formatContent(data) {
            
			let content = data.content
            
			if (data.notificationType == 3) {
				content = content.replace("<br>", "")
			}
			return content
		},
		formatUrl(data) {
			let url = {}
            let notificationType = parseInt(data.notificationType);
			if (notificationType == 1) {
				url = {
					name: 'announcement',
					params:{ id: data.notificationId }
				}
			} else if ([2,3,7].includes(notificationType)) {
				let redirectDetail = data;
                let sportTypePath;
				if ([2,3].includes(notificationType)) {
					sportTypePath = "football";
				} else if (notificationType === 7) {
					sportTypePath = "basketball";
				}
			
				let countrKey = redirectDetail.countryKey
				let leagueKey = redirectDetail.leagueKey
				let matchName = redirectDetail.matchName
				let matchId = redirectDetail.matchId
				url = {
					path: `/${this.currentLocale}/${sportTypePath}/${countrKey}/${leagueKey}/
							${matchName}/
							${ matchId }`
				}
            } else if (notificationType == 4) {
                let redirectDetail = JSON.parse(data.redirectDetails)
				this.contestType.forEach(ele => {
					if (parseInt(redirectDetail.contestType) === ele.type) {
						url = { name: ele.urlName, params: { contestId: redirectDetail.contestId } }
					}
				})
            } else if (notificationType == 5 || notificationType == 6) {
                let redirectDetail = JSON.parse(data.redirectDetails)
                let urlName = redirectDetail.title.toLowerCase().replace(/\s/g, '-');
                let sportTypePath = redirectDetail.sportType.toLowerCase().replace(/\s/g, '-');
                if (sportTypePath == 'basketball') {
					sportTypePath = 'basketball'
				} else if (sportTypePath == 'soccer') {
					sportTypePath = 'football'
				}
                if (notificationType == 5) {
                    url = { name: "newsDetail", params: { title: urlName, id: parseInt(redirectDetail.newsId), currentSportType: sportTypePath }}
                } else {
                    url = { name: "newsDetailScrollTo", params: { title: urlName, id: parseInt(redirectDetail.newsId), scrollTo: "CommentSection", currentSportType: sportTypePath }}
                }
            }
			return url
		},

        async handleClose(o, event, isRedirect) {
            const index = this.list.indexOf(o)
            this.list.splice(index, 1);

            this.verifyToShowBlur();

            if (!isRedirect) {
                event.stopPropagation();
                event.preventDefault();
           
                if (this.list.length === 0) {
                    this.toggleNewNotification(false);
                    const result = await this.getNotificationNotified();
                }
              
            }
        },

        handleMatchCardClick(o, event) {
            this.handleCardClick(o, event);
        },
        
		async handleCardClick(data, event) {
			let params = {
				id: data.notificationId
			}

			const result = await this.getNotificationRead(params);
            this.handleClose(data, event, false)
		},

        verifyToShowBlur() {
            if (this.list.length>2) {
                this.isShowBlurTop = true
            } else {
                this.isShowBlurTop = false
            }
        } 
    }
}
</script>
<style>
.list-enter-active,
.list-leave-active {
  transition: all 0.5s ease;
}
.list-enter-from,
.list-leave-to {
  opacity: 0;
  transform: translateY(50px);
}
.noti-toastr__wrapper{
    position: fixed;
    bottom: 5rem;
    overflow: hidden;
    max-height: 16.5rem;
    left: 2.65625rem;
    display: flex;
    flex-direction: column-reverse;
}
.noti-toastr__blur-top{
    background: linear-gradient(178deg,#040a1c,#6c6969a3,transparent);
    width: 100%;
    height: 3rem;
    position: absolute;
    top: 0;
    z-index: 1;
    pointer-events: none;
}
.noti-toastr__card-container{
    transition: all .3s
}
.noti-toastr__card{
    background-color: white;
    border-radius: 0.5rem;
    color: black;
    display: flex;
    align-items: center;
    padding: .5rem 1rem;
    margin-bottom: 1rem;
    min-height: 3rem;
}
.noti-toastr__card-icon{
    margin-right:1.125rem
}
.noti-toastr__card-content{
    width: 16.4375rem;
    margin-right:1.125rem
}
.noti-toastr__card-close{
    position: relative;
    top: -1rem;
    cursor: pointer;
}
.noti-toastr__card-content-title{
    font-size: 0.875rem;
    color: black;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    margin-bottom: 0.1rem;
}
.noti-toastr__card-content-time{
    color: var(--bg-selected);
    font-weight: 700;
    font-size: 0.875rem   
}
</style>