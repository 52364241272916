<template>
  <Transition name="opacity">
    <div
      class="toastr-icon-with-bg__wrapper"
      :class="position"
      v-if="isShowToastr"
    >
      <div class="toastr-icon-with-bg__container">
        <i v-if="toastrIconWithBg.type === 'success'" class="toastr-icon-with-bg__img">
          <svg
            width="21"
            height="20"
            viewBox="0 0 21 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10.8906 0C5.37063 0 0.890625 4.48 0.890625 10C0.890625 15.52 5.37063 20 10.8906 20C16.4106 20 20.8906 15.52 20.8906 10C20.8906 4.48 16.4106 0 10.8906 0ZM8.89062 15L3.89062 10L5.30062 8.59L8.89062 12.17L16.4806 4.58L17.8906 6L8.89062 15Z"
              fill="#5CD66C"
            />
          </svg>
        </i>
        <i v-else-if="toastrIconWithBg.type === 'warning'" class="toastr-icon-with-bg__img">
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM11 15H9V13H11V15ZM11 11H9V5H11V11Z"
              fill="#FBBC04"
            />
          </svg> 
        </i>

        <div class="toastr-icon-with-bg__text">
          <span>{{ $t(toastrIconWithBg.msg) }}</span>
        </div>
      </div>
    </div>
  </Transition>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  data() {
    return {
      position: "", //bottom
      isShowToastr: false,
      isShowToastrContent: false,
      closeTime: 1200, //2s
      incomingToastr: [],
      currentToastr: {},
    };
  },
  watch: {
    toastrIconWithBg: {
      deep: true,
      handler(news, prev) {
        if (news.isOpen) {
          this.handleOpen();
        }
      },
    },
  },
  computed: {
    ...mapGetters(["isMobile", "toastrIconWithBg"]),
  },
  mounted() {
    this.init();
  },
  methods: {
    ...mapActions(["toastrIconWithBgChangeEvent"]),

    init() {
      this.position = this.isMobile ? "bottom" : "top";
    },

    handleOpen(info) {
      this.isShowToastr = true;

      setTimeout(() => {
        this.isShowToastrContent = true;
      }, 100);

      this.currentToastr = info;

      setTimeout(() => {
        this.handleClose();
        if (this.incomingToastr.length > 0) {
        }
      }, this.closeTime);
    },
    handleClose() {
      setTimeout(() => {
        this.isShowToastr = false;
      }, 0);

      let params = {
        isOpen: false,
      };

      this.toastrIconWithBgChangeEvent(params);

      this.isShowToastrContent = false;
    },
  },
};
</script>

<style scoped>
.toastr-icon-with-bg__container {
  background-color: white;
  border-radius: 2rem;
  padding: 0.5rem;
  font-size: 0.875rem;
  display: flex;
  align-items: center;
}
.toastr-icon-with-bg__img {
  margin-right: 0.625rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.toastr-icon-with-bg__wrapper {
  display: flex;
  justify-content: center;
  position: fixed;
  width: 90%;
  margin: 0 5%;
  z-index: 11;
}
.toastr-icon-with-bg__text {
  color: black;
}

.toastr-icon-with-bg__wrapper.top {
  top: 3.5rem;
}
.toastr-icon-with-bg__wrapper.bottom {
  bottom: 5rem;
}
</style>
