import config from "@/js/config.js";
import router from "@/router";
import { isSSR } from "@/shared/misc";

import moment from "moment";
import km from "@/js/moment/km.js";

import { mapGetters, mapActions, mapMutations } from "vuex";

 router.beforeEach(async(to, from, next) => {
  next();
});

export default {
  data() {
    return {
      isHomePage: null,
    };
  },
  watch: {
    $route(to, from) {
      this.setCurrentLanguage(to.params.locale, from);

      this.footerHandler(to);
      this.headerHandler(to);

      //to solve only mobile home page header different with other header
      if (to.meta.title === "home" || to.meta.title === "basketball") {
      
        this.isHomePage = true;
      } else {
        this.isHomePage = false;
      }

      //this.changeTitle(to, from);
      this.verifyToShowHeader(this.isHomePage);

      //fix AS-627 bug (faster way to solve)
      setTimeout(() => {
        this.getContentListData();
      }, 500);

      this.handleStickyBanner();

      this.setCurrentPathNameEvent();

      this.verifyIsWalkThrough();
    },
    // isLogin: {
    //   deep: true,
    //   handler(prev, nesw) {
    //     this.getContentListData();
    //   },
    // },
    currentLocale: {
      deep: true,
      handler(newVal, oldVal) {
        this.setCurrentLanguage(newVal);
      },
    },
    userSwitchCountryLang: {
      handler(newVal, oldVal) {
        if (newVal && this.currentLanguageObj.displayName === "English") {
          this.getContentListData();
        }
      }
    },
    currentSportTypeParam: {
      handler(newVal, oldVal) {
        this.getContentListData();
      }
    },
  },

  beforeMount() {
    window.addEventListener("resize", this.resizeHandler);
  },
  ...mapMutations([
    "DEFINED_CURRENT_SPORTTYPE"
  ]),
  computed: {
    ...mapGetters([
      "isMobile",
      "footerTabs",
      "headerTabs",
      "userInfo",
      "currentLocale",
      "currentLanguageObj",
      "isLogin",
      "userSwitchCountryLang",
      "currentSportTypeParam",
    ]),
  },
  created() {
    //to solve only mobile home page header different with other header
    if (this.$route.meta.title === "home" || this.$route.meta.title === "basketball") {
      this.isHomePage = true;
    } else {
      this.isHomePage = false;
    }

    // user altered "/:locale" of the path to unsupported language
    if (typeof this.currentLanguageObj === "undefined") {
      window.location.replace(window.location.origin); 
    }

    this.$i18n.locale = this.currentLanguageObj.displayLocale;
    moment.locale("km", km);
    moment.locale(this.currentLanguageObj.locale);

    this.verifyToShowHeader(this.isHomePage);
  },
  mounted() {
    //initital to check whether device is in which mobile or desktop mode
    this.resizeHandler();

    //verify login status
    //this.changeTitle();
    this.clearServiceWorkerCahce();
    this.setCurrentSportType();
    this.setCurrentLanguage();
    this.initToastr();
    this.verifyLogin();
    this.footerHandler();
    this.headerHandler();
    this.$nextTick(() => this.getContentListData());
    this.handleStickyBanner();

    this.setCurrentPathNameEvent();
    this.verifyIsWalkThrough();

  },

  methods: {
    ...mapActions([
      "deviceChangeSizeEvent",
      "isShowHeaderEvent",
      "currentLocaleChangeEvent",
      "isLoginEvent",
      "logoutEvent",
      "getContentList",
      "navigationMenuEvent",
      "isShowStickyBannerEvent",
      "setCurrentPathName",
      "defineSportType"
    ]),
    setCurrentSportType() {
      let currentSportType = window.location.pathname.split("/")[2];
			if (currentSportType === 'news') {
				currentSportType = 'all'
			}
      this.defineSportType(currentSportType)
    },
    setCurrentLanguage(to, from) {
      if (typeof to === "undefined") {
        let fullPath = window.location.pathname.split("/");

        let locale = fullPath[1];
        let currentLanguageObj;

        config.languageList.forEach((x) => {
          if (x.displayLocale === locale) {
            currentLanguageObj = x;
          }
        });

        // set multi language for i18n
        this.$i18n.locale = currentLanguageObj.displayLocale;

        //set multi language for moment.js
        moment.locale(this.convertMomentLocale(currentLanguageObj));
  
        this.currentLocaleChangeEvent(currentLanguageObj.displayLocale);
      } else {
        let toLocale = to;

        let currentLanguageObj;

        config.languageList.forEach((x) => {
          if (x.displayLocale === toLocale) {
            currentLanguageObj = x;
          }
        });
        
        // set multi language for i18n
        this.$i18n.locale = currentLanguageObj.displayLocale;

        //set multi language for moment.js
        moment.locale(this.convertMomentLocale(currentLanguageObj));

        // set currentLocale variable
        this.currentLocaleChangeEvent(currentLanguageObj.displayLocale);
      }
    },
    globalHelper() {
      alert("Hello world");
    },
    isMobileChecking(e) {
      let deviceWidth = 0;
      if (typeof e === "undefined") {
        deviceWidth = window.innerWidth;
      } else {
        deviceWidth = e.currentTarget.innerWidth;
      }

      if (deviceWidth > config.mobileWidth) {
        return false;
      } else {
        return true;
      }
    },

    resizeHandler(e) {
      const isMobile = this.isMobileChecking(e);

      this.deviceChangeSizeEvent(isMobile);
      this.verifyToShowHeader();
    },

    verifyToShowHeader() {
      //only show header for
      //homepage desktop and mobile,
      //otherpage desktop
      let isHomePage = this.isHomePage;
      let isOtherPage = !isHomePage;
      let isDesktop = !this.isMobile;
      let isMobile = this.isMobile;

      let isShowHeader = true;

      if (isHomePage) {
        isShowHeader = true;
      } else if (isOtherPage && isDesktop) {
        isShowHeader = true;
      } else if (isOtherPage && isMobile) {
        isShowHeader = false;
      }
      this.isShowHeaderEvent(isShowHeader);
    },

    verifyLogin() {
      let localStorageUser = localStorage.getItem("user");

      //if local storage has user data mean status is Login
      if (localStorageUser !== null) {
        if (localStorageUser !== "null") {
          this.isLoginEvent();

          // get user preferredLanguage and set it to website
          let preferredLanguage = this.userInfo.preferredLanguage;
          // set currentLocale variable
          this.currentLocaleChangeEvent(preferredLanguage);
        }
      } else {
        this.logoutEvent();
      }
    },
    clearServiceWorkerCahce() {
      if (typeof caches !== "undefined") {
        caches.keys().then(function(names) {
          for (let name of names) caches.delete(name);
        });
      }
    },

    changeTitle(to, from) {
      if (typeof to === "undefined") {
        to = this.$route;
      }
      
      let projectName = config.projectName;
      let title = projectName;
      let descriptionTitle = descriptionTitle;
      let tagsTitle = tagsTitle;
      const whiteList = {
        en: {
          team: [
            {
              id: 59,
              displayName: "Leicester City",
            },
            {
              id: 27,
              displayName: "Manchester United",
            },
            {
              id: 25,
              displayName: "Liverpool",
            },
            {
              id: 19,
              displayName: "Arsenal",
            },
            {
              id: 26,
              displayName: "Manchester City",
            },
          ],
        },
        th: {
          team: [
            {
              id: 27,
              displayName: "สโมสร แมนฯ ยูไนเต็ด ",
            },
            {
              id: 25,
              displayName: "สโมสร ลิเวอร์พูล ",
            },
            {
              id: 19,
              displayName: "สโมสร อาร์เซน่อล ",
            },
            {
              id: 26,
              displayName: "สโมสร แมนฯ ซิตี้",
            },
            {
              id: 59,
              displayName: "สโมสร เลสเตอร์ซิตี้",
            },
            {
              id: 4028,
              displayName: "บุรีรัมย์ยูไนเต็ด ",
            },
            {
              id: 9960,
              displayName: "บีจีปทุม ยูไนเต็ด ",
            },
            {
              id: 5211,
              displayName: "แบงค็อก ยูไนเต็ด ",
            },
            {
              id: 9946,
              displayName: "เมืองทอง ยูไนเต็ด ",
            },
          ],
        },
        vn: {
          team: [
            {
              id: 2026,
              displayName: "Hoàng Anh Gia Lai",
              description: "câu lạc bộ Hoàng Anh Gia Lai",
              tags:
                "Hoàng Anh Gia Lai, CLB Hoàng Anh Gia Lai, câu lạc bộ Hoàng Anh Gia Lai, lịch thi đấu HAGL, đội hình HAGL, soi kèo HAGL",
            },
            {
              id: 24875,
              displayName: "Viettel",
              description: "Viettel Club",
              tags:
                "Câu lạc bộ Viettel, Viettel Club, lịch thi đấu Viettel, soi kèo Viettel club",
            },
            {
              id: 20032,
              displayName: "Than Quảng Ninh",
              description: "Than Quảng Ninh",
              tags:
                "TThan Quảng Ninh, câu lạc bộ Than Quảng Ninh, đội hình Than Quảng Ninh, soi kèo Than Quảng Ninh",
            },
            {
              id: 7241,
              displayName: "Nam Định",
              description: "CLB Nam Định",
              tags:
                "clb Nam Định, Câu lạc bộ Nam Định, đội hình Nam Định, soi kèo Nam Định",
            },
          ],
        },
        cn: {
          team: [
            {
              id: 59,
              displayName: "莱斯特城",
            },
            {
              id: 27,
              displayName: "曼联",
            },
            {
              id: 25,
              displayName: "利物浦",
            },
            {
              id: 19,
              displayName: "阿森纳",
            },
            {
              id: 26,
              displayName: "曼城",
            },
          ],
        },
        kh: {
          team: [
            {
              id: 59,
              displayName: "Leicester City",
            },
            {
              id: 27,
              displayName: "Manchester United",
            },
            {
              id: 25,
              displayName: "Liverpool",
            },
            {
              id: 19,
              displayName: "Arsenal",
            },
            {
              id: 26,
              displayName: "Manchester City",
            },
          ],
        },
      };
      const teamTitle = {
        kh: "ក្លឹបបាល់ទាត់",
      };
      const teamTitleList = {
        en: " Football Club, Lineups, Fixtures, News",
        th: "รายชื่อผู้เล่น โปรแกรมแข่งขัน ข่าว",
        vn: ": Đội hình, Lịch thi đấu, Tin tức",
        kh: "ជម្រើស ១១ នាក់, ពិន្ទុក្នុងតារាង ព័ត៌មានក្លឹប ពី ",
        cn: "足球俱乐部，阵容，赛程，新闻",
      };
      const descriptionList = {
        vn: "Cập nhật kết quả bóng đá trực tiếp",
      };
      const descriptionListEnd = {
        vn:
          "nhanh nhất, bảng xếp hạng V League và tin tức, soi kèo bóng đá bởi các chuyên gia săn tin hàng đầu tại",
      };
 
      if (to.params.locale === "en") {
        if (to.fullPath === "/en" || to.fullPath === "/en/") {
          document.title = `Live Football Results, Live Scores, Fixtures, Video Highlights - ${projectName}`;
        } else if (
          to.fullPath ===
            "/en/football/english-premier-league/english-premier-league-36?leagueId=36" ||
          to.fullPath ===
            "/en/football/english-premier-league/english-premier-league-36?leagueId=36/"
        ) {
          document.title = `English Premier League, EPL Table, Fixtures, Highlights - ${projectName}`;
        }
        // news page title
        else if (to.name === "news") {
          document.title = `Watch Football Online, Soccer Video Highlights, Livestream - ${projectName}`;
        }
        // highlight page title
        else if (to.name === "highlights") {
          document.title = `Football Highlights, Live Football Results, Soccer Highlights - ${projectName}`;
        }
        // event page title
        else if (to.name === "event") {
          document.title = `Football Contests, Soccer Contests & Events - ${projectName}`;
        }
        // myProfile page title
        else if (to.name === "myProfile") {
          document.title = `My Profile - ${projectName}`;
        }
        // player profile page title
        else if (
          to.name === "footballPlayerOverview" ||
          to.name === "footballPlayerCarrer" ||
          to.name === "footballPlayerTransfer" ||
          to.name === "basketballPlayerOverview" ||
          to.name === "basketballPlayerCarrer" ||
          to.name === "basketballPlayerTransfer"
        ) {
          document.title = `${to.params.playerName
            .split("-")
            .join(" ")} Profile - ${projectName}`;
        } // matchdetail page title
        else if (
          to.meta.category === "match detail" ||
          to.name === "highlightsDetail"
        ) {
          document.title = `${to.params.opponent
            .split("-")
            .join(" ")} Video Highlights - ${projectName}`;
        } else {
          document.title = projectName;
        }
      } else if (to.params.locale === "th") {
        if (to.fullPath === "/th" || to.fullPath === "/th/") {
          document.title = `วิเคราะห์บอลวันนี้ ผลบอลสด โปรแกรมแข่งขัน ข่าวกีฬา ไฮไลท์- ${projectName}`;
        } else if (
          to.fullPath ===
            "/th/football/english-premier-league/english-premier-league-36?leagueId=36" ||
          to.fullPath ===
            "/th/football/english-premier-league/english-premier-league-36?leagueId=36/"
        ) {
          document.title = `พรีเมียร์ลีกอังกฤษ, ตารางคะแนน, โปรแกรมแข่งขัน, ไฮไลท - ${projectName}`;
        } else if (
          to.fullPath ===
            "/th/football/thailand/thai-premier-league-700?leagueId=700" ||
          to.fullPath ===
            "/th/football/thailand/thai-premier-league-700?leagueId=700/"
        ) {
          document.title = `ไทยลีก1 โปรแกรมแข่งขัน ตารางคะแนน ไฮไลท - ${projectName}`;
        }
        // news page title
        else if (to.name === "news") {
          document.title = `ข่าวกีฬา ข่าวแมนยู ข่าวลิเวอร์พูล ข่าวบอล - ${projectName}`;
        }
        // highlight page title
        else if (to.name === "highlights") {
          document.title = `ดูบอลออนไลน์ ไฮไลท์ฟุตบอล บอลสด ผลบอลเมื่อคืน - ${projectName}`;
        }
        // event page title
        else if (to.name === "event") {
          document.title = `โปรแกรมบอล โปรแกรมฟุตบอล การแข่งขันบอล- ${projectName}`;
        }
        // myProfile page title
        else if (to.name === "myProfile") {
          document.title = `My Profile - ${projectName}`;
        }
        // player profile page title
        else if (
          to.name === "footballPlayerOverview" ||
          to.name === "footballPlayerCarrer" ||
          to.name === "footballPlayerTransfer" ||
          to.name === "basketballPlayerOverview" ||
          to.name === "basketballPlayerCarrer" ||
          to.name === "basketballPlayerTransfer"
        ) {
          document.title = `${to.params.playerName
            .split("-")
            .join(" ")} โปรไฟล์ - ${projectName}`;
        } // matchdetail page title
        else if (to.meta.category === "match detail") {
          document.title = `${to.params.opponent
            .split("-")
            .join(" ")} คลิปไฮไลท์ - ${projectName}`;
        } else {
          document.title = projectName;
        }
      } else if (to.params.locale === "vn") {
        if (to.fullPath === "/vn" || to.fullPath === "/vn/") {
          (document.title = `Kết quả bóng đá, tỷ số trực tiếp, lịch thi đấu, video bóng đá - ${projectName}`),
            (document.getElementsByName(
              "description"
            )[0].content = `Xem kết quả bóng đá đang diễn ra Premier League, UEFA Champions League, La Liga, Bundesliga, Serie A và các câu lạc bộ bạn yêu thích tại ${projectName}!`);
          document.getElementsByName(
            "tags"
          )[0].content = `Kết quả bóng đá, tỷ số trực tiếp, lịch thi đấu, video bóng đá`;
        } else if (
          to.fullPath ===
            "/vn/football/vietnam/vietnamese-professional-league-766?leagueId=766" ||
          to.fullPath ===
            "/vn/football/vietnam/vietnamese-professional-league-766?leagueId=766/"
        ) {
          (document.title = `V League 1: Bảng xếp hạng, Lịch thi đấu, Soi kèo - ${projectName}`),
            (document.getElementsByName(
              "description"
            )[0].content = `Cập nhật kết quả bóng đá trực tiếp V League nhanh nhất, bảng xếp hạng V League và tin tức, soi kèo bóng đá bởi các chuyên gia săn tin hàng đầu tại ${projectName}!`);
          document.getElementsByName(
            "tags"
          )[0].content = `V League, bảng xếp hạng, lịch thi đấu V League, Soi kèo V League`;
        }
        // news page title
        else if (to.name === "news") {
          document.title = `Tin tức bóng đá việt nam, Ngoại hạng anh, Bongdalive - ${projectName}`;
        }
        // highlight page title
        else if (to.name === "highlights") {
          document.title = `Trực tiếp boóng đá hôm nay, Xem bong da truc tiêp, kết quả - ${projectName}`;
        }
        // event page title
        else if (to.name === "event") {
          document.title = `Chương trình bóng đá, bóng đá trực tuyến - ${projectName}`;
        }
        // myProfile page title
        else if (to.name === "myProfile") {
          document.title = `My Profile - ${projectName}`;
        }
        // player profile page title
        else if (
          to.name === "footballPlayerOverview" ||
          to.name === "footballPlayerCarrer" ||
          to.name === "footballPlayerTransfer" ||
          to.name === "basketballPlayerOverview" ||
          to.name === "basketballPlayerCarrer" ||
          to.name === "basketballPlayerTransfer"
        ) {
          document.title = `${to.params.playerName
            .split("-")
            .join(" ")} Hồ sơ - ${projectName}`;
        } // matchdetail page title
        else if (to.meta.category === "match detail") {
          document.title = `${to.params.opponent
            .split("-")
            .join(" ")} Video Điểm Nhấn Trận Đấu - ${projectName}`;
        } else {
          document.title = projectName;
        }
      } else if (to.params.locale === "kh") {
        if (to.fullPath === "/kh" || to.fullPath === "/kh/") {
          document.title = `ផ្សាយផ្ទាល់លទ្ធផលបាល់ទាត់ ពិន្ទុ តារាងការប្រកួត សកម្មភាពហាយឡាយ ពី - ${projectName}`;
        } else if (
          to.fullPath ===
            "/kh/football/english-premier-league/english-premier-league-36?leagueId=36" ||
          to.fullPath ===
            "/kh/football/english-premier-league/english-premier-league-36?leagueId=36/"
        ) {
          document.title = `ក្របខណ្ឌបាល់ទាត់អង់គ្លេស EPL, តារាងពិន្ទុ, តារាងការប្រកួត ពី - ${projectName}`;
        }
        // news page title
        else if (to.name === "news") {
          document.title = `ព័ត៌មានកីឡាបាល់ទាត់, English Premier League News - ${projectName}`;
        }
        // highlight page title
        else if (to.name === "highlights") {
          document.title = `បាល់ទាត់ Football Highlights, Livescore, Videos - ${projectName}`;
        }
        // event page title
        else if (to.name === "event") {
          document.title = `ការប្រកួតបាល់ទាត់, Football Contests & Events - ${projectName}`;
        }
        // myProfile page title
        else if (to.name === "myProfile") {
          document.title = `My Profile - ${projectName}`;
        }
        // player profile page title
        else if (
          to.name === "footballPlayerOverview" ||
          to.name === "footballPlayerCarrer" ||
          to.name === "footballPlayerTransfer" ||
          to.name === "basketballPlayerOverview" ||
          to.name === "basketballPlayerCarrer" ||
          to.name === "basketballPlayerTransfer"
        ) {
          document.title = `${to.params.playerName
            .split("-")
            .join(" ")} Profile - ${projectName}`;
        } // matchdetail page title
        else if (to.meta.category === "match detail") {
          document.title = `${to.params.opponent
            .split("-")
            .join(" ")} វីដេអូហាយឡាយ - ${projectName}`;
        } else {
          document.title = projectName;
        }
      } else if (to.params.locale === "cn") {
        if (to.fullPath === "/cn" || to.fullPath === "/cn/") {
          document.title = `实况足球赛果、比分、赛程、视频集锦 - ${projectName}`;
        } else if (
          to.fullPath ===
            "/cn/football/english-premier-league/english-premier-league-36?leagueId=36" ||
          to.fullPath ===
            "/cn/football/english-premier-league/english-premier-league-36?leagueId=36/"
        ) {
          document.title = `英超联赛，EPL 表，赛程，集锦 - ${projectName}`;
        } else {
          document.title = projectName;
        }
      } else {
        document.title = projectName;
      }
      // team overview
      if (to.name === "footballTeamOverview" || to.name === "basketballTeamOverview") {
        let team = whiteList[to.params.locale].team;
        for (let i = 0; i < team.length; i++) {
          if (team[i].id === parseInt(to.params.teamKey)) {
            if (to.params.locale === "kh") {
              title = `${teamTitle[to.params.locale]} ${team[i].displayName} ${
                teamTitleList[to.params.locale]
              } - ${projectName}`;
            } else {
              title = `${team[i].displayName} ${
                teamTitleList[to.params.locale]
              } - ${projectName}`;
            }
            document.title = title;
            descriptionTitle = `${descriptionList[to.params.locale]} ${
              team[i].description
            } ${descriptionListEnd[to.params.locale]} ${projectName}`;
            document.getElementsByName(
              "description"
            )[0].content = descriptionTitle;
            tagsTitle = `${team[i].tags}`;
            document.getElementsByName("tags")[0].content = tagsTitle;
          }
        }
      }
    },

    footerHandler() {
      let e = this.$route;
      
      for (let i = 0; i < this.footerTabs.length; i++) {
        if (this.footerTabs[i].id === e.meta.id) {
          this.footerTabs[i].selected = true;
        } else {
          this.footerTabs[i].selected = false;
        }
      }
    },

    headerHandler() {
      let e = this.$route;
      
      for (let i = 0; i < this.headerTabs.length; i++) {
        if (this.headerTabs[i].id === e.meta.id) {
          this.headerTabs[i].selected = true;
        } else {
          this.headerTabs[i].selected = false;
        }
      }
    },

    initToastr() {
      if (this.isMobile) {
        this.$parent.toastrPosition = "bottom";
      } else {
        this.$parent.toastrPosition = "top";
      }
    },

    async getContentListData() {
      let route = this.$route;
      
      if (route.name !== "event") {}
        let params = {
          timeType: 2,
          language: this.currentLanguageObj.locale,
          lang: this.currentLanguageObj.apiParam,
          sportType: this.currentSportTypeParam,
        };

        const result = await this.getContentList(params);

        let returnResult = result.result;

        this.navigationMenuEvent(returnResult);
      
    },


    handleStickyBanner() {
      let isShowStickyBanner = true;
      let notShowStickyBannerList = ['oddsResult', 'oddsResultFilter'];
 
      if (notShowStickyBannerList.includes(this.$route.name) ) {
        isShowStickyBanner = false;
      }

      if (this.$route.meta.parent) {
        if (this.$route.meta.parent === 'account') {
          isShowStickyBanner = false;
        }
      }
        this.isShowStickyBannerEvent(isShowStickyBanner)
    },

    setCurrentPathNameEvent() {
      this.setCurrentPathName(this.$route.fullPath)//window.location.pathname
    },

    verifyIsWalkThrough() {
      if (this.isLogin) {		
        if (!this.userInfo.walkthroughFlag){
          // Enable on basketball Phase 2
          // this.$router.push({name: "home"})
        }
      }
    },

    convertMomentLocale(o) {
      let locale = o.locale;
      if (o.locale === "zh-Hant" || o.locale === "zh-Hans") {
        locale = o.momentLocale;
      }
      return locale
    }
  },
};
