import config from "@/js/config.js";
import footerLinks from "@/js/seoArticles/footer.js";

const projectName = config.projectName;
const description = `AsiaSports is a current hub where all the sports fans gather for newsfeed. Every aspect of the tournament will be made accessible at all times. When a football match is held, the live scores will be updated in real-time as it progresses.`;
const footerLinksPageMeta = {};
Object.values(footerLinks).forEach((arr) => {
  if (arr.length > 0) {
    arr.forEach((article) => {
      footerLinksPageMeta[article.id] = (route) => ({
        title: article.titleTag,
        description: article.metaDescription,
      });
    });
  }
});

export default {
  home: (route) => ({
    title: `Resultados de Futebol em Direto, Resultados em Direto, Jogos, Destaques em Vídeo - ${projectName}`,
    description: description,
  }),
  highlights: (route) => ({
    title: `Destaques de Futebol, Resultados de Futebol em Direto, Destaques de Futebol - ${projectName}`,
    description: description,
  }),
  news: (route) => ({
    title: `Ver Futebol Online, Destaques de Vídeo de Futebol, Transmissão ao Vivo - ${projectName}`,
    description: description,
  }),
  event: (route) => ({
    title: `Concursos de Futebol e Eventos - ${projectName}`,
    description: description,
  }),
  account: (route) => ({
    title: `Meu Perfil - ${projectName}`,
    description: description,
  }),
  player: (route) => ({
    title: `${route.params.playerName
      .split("-")
      .join(" ")} Perfil - ${projectName}`,
    description: description,
  }),
  matchDetail: (route) => ({
    title: `${route.params.opponent
      .split("-")
      .join(" ")} Melhores momentos em vídeo - ${projectName}`,
    description: description,
  }),
  highlightsDetail: (route) => ({
    title: `${route.params.opponent
      .split("-")
      .join(" ")} Melhores momentos em vídeo - ${projectName}`,
    description: description,
  }),
  ...footerLinksPageMeta,
};
