<template>
  <svg v-if="pathName === 'home' && !selected" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.99991 1.33334C6.23185 1.33334 4.53612 2.03582 3.28607 3.28607C2.03585 4.53628 1.33334 6.23198 1.33334 7.99991C1.33334 9.76811 2.03582 11.4637 3.28607 12.7141C4.53628 13.9643 6.23198 14.6667 7.99991 14.6667C9.76811 14.6667 11.4637 13.9643 12.7141 12.7141C13.9643 11.4639 14.6667 9.76817 14.6667 7.99991C14.6667 6.82975 14.3587 5.68006 13.7735 4.66676C13.1884 3.65333 12.3468 2.81172 11.3334 2.2265C10.32 1.6414 9.17027 1.33334 7.99991 1.33334ZM10.7618 10.9905L9.90473 9.81904L10.6142 7.65241L11.9856 7.20483L13.7143 8.43809C13.6446 9.34254 13.3606 10.2175 12.8857 10.9905H10.7618ZM5.23808 10.9905H3.13806C2.6632 10.2175 2.37914 9.34254 2.30951 8.43809L4.00946 7.20471L5.38085 7.65228L6.09526 9.81904L5.23808 10.9905ZM3.36191 6.49509L2.34281 7.23804C2.43406 6.55481 2.64686 5.89355 2.97138 5.28558L3.36191 6.49509ZM6.96181 9.42842L6.31892 7.45221L7.99988 6.23314L9.68084 7.45221L9.03806 9.42842H6.96181ZM12.6381 6.49509L13.0286 5.28558H13.0285C13.353 5.89355 13.5659 6.55477 13.657 7.23804L12.6381 6.49509ZM12.3476 4.29979L11.6999 6.29498L10.3237 6.74256L8.47608 5.4046V3.97603L10.176 2.73797C11.0133 3.07946 11.7574 3.61478 12.3476 4.29991L12.3476 4.29979ZM9.01411 2.39497L7.99981 3.1237L6.97608 2.38075C7.31406 2.32017 7.65655 2.28824 7.99981 2.28561C8.3432 2.28824 8.68568 2.32017 9.02366 2.38075L9.01411 2.39497ZM5.81406 2.73308L7.52369 3.95704V5.38561L5.67606 6.7237L4.29987 6.27613L3.65219 4.29991C4.24015 3.60786 4.98449 3.06588 5.82359 2.71886L5.81406 2.73308ZM3.87608 11.9426H5.14264L5.53316 13.1426V13.1427C4.91312 12.8456 4.35168 12.4391 3.87608 11.9426V11.9426ZM6.65709 13.5474L6.00941 11.5522L6.85706 10.3808H9.14284L9.9905 11.5522L9.34282 13.5474H9.3427C8.46122 13.7695 7.53843 13.7695 6.65696 13.5474H6.65709ZM10.4665 13.1427L10.8569 11.9426H12.114C11.644 12.4372 11.0892 12.8437 10.4759 13.1427H10.4665Z" fill="white"/>
  </svg>
  <svg v-else-if="pathName === 'home' && selected" class="selected" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.99994 1.33334C6.23188 1.33334 4.53615 2.03582 3.2861 3.28607C2.03588 4.53628 1.33337 6.23198 1.33337 7.99991C1.33337 9.76811 2.03585 11.4637 3.2861 12.7141C4.53631 13.9643 6.23201 14.6667 7.99994 14.6667C9.76814 14.6667 11.4637 13.9643 12.7141 12.7141C13.9643 11.4639 14.6667 9.76817 14.6667 7.99991C14.6667 6.82975 14.3588 5.68006 13.7736 4.66676C13.1885 3.65333 12.3469 2.81172 11.3334 2.2265C10.32 1.6414 9.1703 1.33334 7.99994 1.33334ZM10.7618 10.9905L9.90476 9.81904L10.6143 7.65241L11.9857 7.20483L13.7143 8.43809C13.6447 9.34254 13.3606 10.2175 12.8857 10.9905H10.7618ZM12.3475 4.32382L11.6999 6.32378L10.3237 6.76659L8.47616 5.40476V3.97619L10.1809 2.73813C11.0152 3.08226 11.7573 3.61719 12.3476 4.30008L12.3475 4.32382ZM5.81893 2.71902L7.52377 3.95717V5.38574L5.67613 6.72383L4.29994 6.28102L3.65227 4.30004C4.24048 3.61024 4.9827 3.06865 5.8189 2.71899L5.81893 2.71902ZM3.13799 10.9906C2.66313 10.2176 2.37908 9.3427 2.30945 8.43825L4.00939 7.20487L5.38078 7.65244L6.0952 9.8192L5.23798 10.9906H3.13799ZM6.65713 13.5478L6.00945 11.5526L6.85723 10.3812H9.14301L9.99067 11.5526L9.34299 13.5478H9.34286C8.46139 13.7699 7.5386 13.7699 6.65713 13.5478H6.65713Z" fill="black"/>
  </svg>

  <svg v-if="pathName === 'basketball' && !selected" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M1.33337 2.84841C1.33337 2.17898 1.87606 1.63629 2.5455 1.63629H13.4546C14.124 1.63629 14.6667 2.17898 14.6667 2.84841V10.1211C14.6667 10.7906 14.124 11.3333 13.4546 11.3333H2.5455C1.87606 11.3333 1.33337 10.7906 1.33337 10.1211V2.84841ZM13.4546 2.84841H2.5455V10.1211H13.4546V2.84841Z" fill="white"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M5.27271 5.27269C5.27271 4.93797 5.54405 4.66663 5.87877 4.66663H10.1212C10.4559 4.66663 10.7273 4.93797 10.7273 5.27269V7.3939C10.7273 7.72862 10.4559 7.99996 10.1212 7.99996C9.78647 7.99996 9.51513 7.72862 9.51513 7.3939V5.87875H6.48483V7.3939C6.48483 7.72862 6.21348 7.99996 5.87877 7.99996C5.54405 7.99996 5.27271 7.72862 5.27271 7.3939V5.27269Z" fill="white"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M5.57581 12.5454C5.57581 12.2107 5.84715 11.9394 6.18187 11.9394H9.81823C10.1529 11.9394 10.4243 12.2107 10.4243 12.5454C10.4243 12.8801 10.1529 13.1515 9.81823 13.1515H6.18187C5.84715 13.1515 5.57581 12.8801 5.57581 12.5454Z" fill="white"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M5.27271 10.7272C5.27271 10.3925 5.54405 10.1212 5.87877 10.1212H10.1212C10.4559 10.1212 10.7273 10.3925 10.7273 10.7272C10.7273 11.0619 10.4559 11.3333 10.1212 11.3333H5.87877C5.54405 11.3333 5.27271 11.0619 5.27271 10.7272Z" fill="white"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M10.5239 8.31132C10.8541 8.36635 11.0771 8.67861 11.0221 9.00877L10.4368 12.5208L10.7092 13.6106C10.7904 13.9354 10.593 14.2644 10.2682 14.3456C9.94352 14.4268 9.61446 14.2293 9.53328 13.9046L9.23025 12.6925C9.21009 12.6118 9.20674 12.5279 9.2204 12.4459L9.82646 8.8095C9.88149 8.47933 10.1938 8.25629 10.5239 8.31132Z" fill="white"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M5.47612 8.31132C5.80629 8.25629 6.11855 8.47933 6.17357 8.8095L6.77963 12.4459C6.7933 12.5279 6.78995 12.6118 6.76979 12.6925L6.46676 13.9046C6.38557 14.2293 6.05652 14.4268 5.7318 14.3456C5.40707 14.2644 5.20964 13.9354 5.29082 13.6106L5.56328 12.5208L4.97795 9.00877C4.92292 8.67861 5.14596 8.36635 5.47612 8.31132Z" fill="white"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M7.99998 8.30298C8.3347 8.30298 8.60604 8.57432 8.60604 8.90904V13.7575C8.60604 14.0922 8.3347 14.3636 7.99998 14.3636C7.66526 14.3636 7.39392 14.0922 7.39392 13.7575V8.90904C7.39392 8.57432 7.66526 8.30298 7.99998 8.30298Z" fill="white"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M4.36365 8.90904C4.36365 8.57432 4.63499 8.30298 4.96971 8.30298H11.0303C11.365 8.30298 11.6364 8.57432 11.6364 8.90904C11.6364 9.24376 11.365 9.5151 11.0303 9.5151H4.96971C4.63499 9.5151 4.36365 9.24376 4.36365 8.90904Z" fill="white"/>
  </svg>
  <svg v-else-if="pathName === 'basketball' && selected" class="selected" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M1.33331 2.84838C1.33331 2.17895 1.876 1.63626 2.54543 1.63626H13.4545C14.124 1.63626 14.6666 2.17895 14.6666 2.84838V10.1211C14.6666 10.7905 14.124 11.3332 13.4545 11.3332L11.6392 11.3332V11.3312L11.6487 11.3312C11.6487 11.3312 11.6429 10.3451 11.6392 9.61968V9.28604C11.6392 9.26529 11.6386 9.24469 11.6373 9.22425C11.6367 9.07934 11.6363 8.96744 11.6363 8.90899C11.6363 8.57427 11.365 8.30292 11.0303 8.30292H10.8231C10.7635 8.29184 10.702 8.28604 10.6392 8.28604H5.37146C5.30863 8.28604 5.24715 8.29184 5.18753 8.30292H4.96965C4.63493 8.30292 4.36359 8.57427 4.36359 8.90899C4.36359 9.09571 4.36777 9.82865 4.37146 10.4403V11.3332H2.54543C1.876 11.3332 1.33331 10.7905 1.33331 10.1211V2.84838ZM5.27268 5.27264C5.27268 4.93792 5.54402 4.66658 5.87874 4.66658H10.1212C10.4559 4.66658 10.7272 4.93792 10.7272 5.27264V7.39386C10.7272 7.72857 10.4559 7.99992 10.1212 7.99992C9.78644 7.99992 9.5151 7.72857 9.5151 7.39386V5.8787H6.4848V7.39386C6.4848 7.72857 6.21346 7.99992 5.87874 7.99992C5.54402 7.99992 5.27268 7.72857 5.27268 7.39386V5.27264Z" fill="black"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M5.5757 12.5454C5.5757 12.2106 5.84705 11.9393 6.18176 11.9393H9.81813C10.1528 11.9393 10.4242 12.2106 10.4242 12.5454C10.4242 12.8801 10.1528 13.1514 9.81813 13.1514H6.18176C5.84705 13.1514 5.5757 12.8801 5.5757 12.5454Z" fill="black"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M5.27918 10.7655C5.27503 10.74 5.27266 10.7144 5.27401 10.6886C5.29275 10.3295 5.55645 9.51041 5.879 9.51041H10.1214C10.4439 9.51041 10.7076 10.3292 10.7264 10.6884C10.7278 10.7143 10.7254 10.74 10.7212 10.7657L10.4999 12.1173C10.4619 12.3492 10.2615 12.5195 10.0266 12.5196L5.97328 12.5214C5.73797 12.5215 5.53731 12.3509 5.49949 12.1186L5.27918 10.7655Z" fill="black"/>
    <path d="M10.4531 12.4223C10.4423 12.4872 10.4449 12.5537 10.4609 12.6176L10.7091 13.6106C10.7903 13.9353 10.5929 14.2643 10.2682 14.3455C9.94343 14.4267 9.61437 14.2293 9.53319 13.9046L9.23016 12.6924C9.21 12.6118 9.20665 12.5278 9.22031 12.4458L9.64194 9.91604C9.6805 9.68469 9.88067 9.51511 10.1152 9.51511H10.3712C10.6677 9.51511 10.8932 9.78135 10.8445 10.0738L10.4531 12.4223Z" fill="black"/>
    <path d="M6.7796 12.4458C6.79327 12.5278 6.78991 12.6118 6.76975 12.6924L6.46672 13.9046C6.38554 14.2293 6.05649 14.4267 5.73176 14.3455C5.40704 14.2643 5.20961 13.9353 5.29079 13.6106L5.53904 12.6176C5.55501 12.5537 5.55766 12.4872 5.54684 12.4223L5.15521 10.0725C5.10646 9.78005 5.33199 9.51382 5.62849 9.51382H5.88447C6.11902 9.51382 6.31919 9.68339 6.35775 9.91475L6.7796 12.4458Z" fill="black"/>
    <path d="M8.606 13.7575C8.606 14.0922 8.33466 14.3635 7.99994 14.3635C7.66523 14.3635 7.39388 14.0922 7.39388 13.7575V9.99409C7.39388 9.7291 7.6087 9.51428 7.87369 9.51428H8.12619C8.39119 9.51428 8.606 9.7291 8.606 9.99409V13.7575Z" fill="black"/>
  </svg>

  <svg v-if="pathName === 'news' && !selected" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12.6667 3.33333V12.6667H3.33333V3.33333H12.6667ZM13.4 2H2.6C2.26667 2 2 2.26667 2 2.6V13.4C2 13.6667 2.26667 14 2.6 14H13.4C13.6667 14 14 13.6667 14 13.4V2.6C14 2.26667 13.6667 2 13.4 2ZM7.33333 4.66667H11.3333V6H7.33333V4.66667ZM4.66667 7.33333H11.3333V8.66667H4.66667V7.33333ZM4.66667 10H11.3333V11.3333H4.66667V10ZM4.66667 4.66667H6V6H4.66667V4.66667Z" fill="white"/>
  </svg>
  <svg v-else-if="pathName === 'news' && selected" class="selected" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M2.6 2H13.4C13.6667 2 14 2.26667 14 2.6V13.4C14 13.6667 13.6667 14 13.4 14H2.6C2.26667 14 2 13.6667 2 13.4V2.6C2 2.26667 2.26667 2 2.6 2ZM7.33333 4.66667H11.3333V6H7.33333V4.66667ZM4.66667 7.33333H11.3333V8.66667H4.66667V7.33333ZM11.3333 10H4.66667V11.3333H11.3333V10ZM4.66667 4.66667H6V6H4.66667V4.66667Z" fill="black"/>
  </svg> 

</template>

<script>
export default {
  props: {
    pathName: {
      type: String,
      required: true,
    },
    selected: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped>
svg {
  margin-right: 0.25rem;
}
.selected rect,
.selected path {
  fill: #000000;
}
</style>
