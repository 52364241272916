<template>

    <Head>
      <title>{{ metadata.title }}</title>
      <meta name="description" :content="metadata.description">
    </Head>
  </template>
  
  <script>
  import { mapGetters, mapActions } from "vuex";
  
  import { Head } from "@vueuse/head";
  import { getMetadata, EMPTY_METADATA } from "@/js/metadata";
  
  export default {
    components: {
      Head
    },
    async serverPrefetch() {
      await this.applyMetadata();
    },
    watch: {
      $route() {
        this.applyMetadata();
      }
    },
    data() {
      return {
        metadata: EMPTY_METADATA
      };
    },
    computed: {
      ...mapGetters(["currentLocale", "metadataProvider"])
      
    },
    mounted() {
      this.applyMetadata();
    },
    methods: {
      ...mapActions(["setMetadataProvider"]),
      applyStaticMetadata() {
        const route = this.$route;
        const routeName = route.name;
        const locale = this.currentLocale;
    
        this.metadata = getMetadata(routeName, locale, route)(route);
      },
      async applyMetadata() {
    
        if (!this.metadataProvider) {
          this.applyStaticMetadata();
        } else {
          this.metadata = await this.metadataProvider();
        }
      }
    }
  };
  
  </script>