<template>
    <img 
      v-if="qrImg != null"
      :class="className"
      :src="require(`../../../../static/images/apk/${qrImg}.png`)"
      alt=""
    />
</template>

<script>
// import config from '@/js/config.js';
import { mapGetters } from 'vuex';

export default {
  props: {
    className: {
      type: String,
      default: "defaut",
    }
  },
  mounted() {
    this.init();
  },
  watch: {
    currentCountryObj: {
      deep: true,
      handler(newVal, oldVal) {
        this.init();
      },
    },
    currentLanguageObj: {
      deep: true,
      handler(newVal, oldVal) {
        this.init();
      },
    },
  },
  computed: {
    ...mapGetters([
      "currentCountryObj",
      "currentLanguageObj",
    ]),
  },
  data() {
    return {
      // stagingEnv: "stagingEnv",
      // prodEnv: "prodEnv",
      qrImg: null,
      // envAlias: config.envAlias,
    };
  },
  methods: {
    init() {
      let countryCode = this.currentCountryObj.countryCode;
      let displayLocale = this.currentLanguageObj.displayLocale;
      if(countryCode === 'TH' && displayLocale === 'th') {
        this.qrImg = "qr-apk-th-page";
      } else if (countryCode === 'TH' && displayLocale === 'en') {
        this.qrImg = "qr-apk-th-en-page";
      }
      // if(countryCode === 'TH' && displayLocale === 'th' && this.envAlias === this.prodEnv) {
      //   this.qrImg = "qr-apk-th-page";
      // } else if (countryCode === 'TH' && displayLocale === 'en' && this.envAlias === this.prodEnv) {
      //   this.qrImg = "qr-apk-th-en-page";
      // } else if (countryCode === 'TH' && displayLocale === 'th' && this.envAlias === this.stagingEnv) {
      //   this.qrImg = "staging-qr-apk-th-page";
      // } else if (countryCode === 'TH' && displayLocale === 'en' && this.envAlias === this.stagingEnv) {
      //   this.qrImg = "staging-qr-apk-th-en-page";
      // }
    },
  },
};
</script>

<style scoped>
.defaut {
  max-width: 86px;
  max-height: 86px;
}
.small {
  max-width: 54px;
  max-height: 54px;
}
</style>
