<template>

  <Transition name="showModal">
    <div
		class="fav-modal display-flex-center"
		@click="$emit('closeModal')"
		v-show="isShowFavModal"
		@keyup.esc="$emit('closeModal')"
    >
      <div class="fav-modal__wrapper" @click="handleContent($event)" >
        <div class="fav-modal__close-wrapper" :class="isMobile ? 'display-flex-center' : 'display-flex-fe'">
			<div class="fav-modal__close" @click="$emit('closeModal')"></div>
			<div class="fav-modal__close-title" v-show="isMobile">{{ $t("SEARCH") }}</div>
        </div>
        <div class="fav-modal__inner-wrapper">
					<!-- Search Box -->
					<div v-show="!country.isShowCountryListOnly" class="search-wrapper">
						<img class="search-icon" src="../../../../static/images/icons/icon_search.png" alt=""/>
						<input
							class="search-input"
							type="text"
							:placeholder="$t('SEARCH')"
							v-model="userInput"
							@input="handleSearchUserInput"
							ref="userInputRef"
						/>
						<div class="search-clear display-flex-center"  @click="handleClearUserInput">            
							<svg
								width="11"
								height="10"
								viewBox="0 0 11 10"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M10.2992 1.16705L9.33236 0.200195L5.49922 4.03334L1.66608 0.200195L0.699219 1.16705L4.53236 5.0002L0.699219 8.83334L1.66608 9.80019L5.49922 5.96705L9.33236 9.80019L10.2992 8.83334L6.46608 5.0002L10.2992 1.16705Z"
									fill="#E7ECEE"
								/>
							</svg>
						</div>
					</div>
					<div v-if="country.isShowCountryListOnly" class="fav-modal__subtitle">
						<div class="display-flex pointer" @click="handleCountryGoBack">
							<div class="fav-model__back">
								<svg width="8" height="13" viewBox="0 0 8 13" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M7.41016 2.01437L2.83016 6.60437L7.41016 11.1944L6.00016 12.6044L0.00015614 6.60437L6.00016 0.60437L7.41016 2.01437Z" fill="white"/>
								</svg>
							</div>
							<div>{{ country.currentCountryDisplay }}</div>
						</div>
					</div>
          <div v-if="isNoData" class="fav-modal__wrapper-list" >
            <div class="display-flex flex-col">
              <div class="fav-modal__subtitle">
                <p>{{ $t("NO_RESULT") }}</p>
              </div>
            </div>
          </div>
          <div v-else 
						class="fav-modal__wrapper-list" 
						ref="favContentListRef" 
						@scroll="handleFavContentScrollEvent($event)" 
						:class="{ 'scrollbar-show': !isMobile }"
					>
            <div :class="{ 'has-btn': isMobile }">
              <div class="display-flex flex-col" v-if="countryResult && countryResult.result.length">
								<div class="fav-modal__subtitle">
									<p>{{ $t("COUNTRY") }}</p>
								</div>
								<div class="fav-modal__modal-card-wrapper fix-h display-flex flex-col">
									<FavCard
										v-for="c in countryResult.result"
										:key="c.id"
										:keyName="c.key"
										type="COUNTRY"
										:name="c.name"
										:id="c.id"
										:logo="c.logo"
										:isFav="c.isFav"
										:object="c"
										@favHandler="favHandler"
										@pageIsRedirected="pageIsRedirected"
									/>
								</div>
              </div>
							<template v-if="tabName === 'FILTER' ">
								<div class="display-flex flex-col" v-for="(o, index) in globalPopularList" :key="index">
									<div v-if ="!country.isShowCountryListOnly" class="fav-modal__subtitle">
										<p>{{ $t(o.title) }}</p>	
									</div>
									<div v-show="isNoCompetitionDataFromCountry">
										<div class="display-flex flex-col">
											<div class="fav-modal__subtitle">
											<p>{{ $t("NO_RESULT") }}</p>
											</div>
										</div>
									</div>
									<div class="fav-modal__modal-card-wrapper fix-h display-flex flex-col">
										<FavCard
											v-for="c in o.list"
											:key="c.id"
											:keyName="c.key"
											:type="country.isShowCountryListOnly ? 'COMPETITIONS' : o.titleType"
											:name="c.name"
											:countryKey="c.countryKey"
											:id="c.id"
											:logo="c.logo"
											:isFav="c.isFav"
											:titleType="o.titleType"
											:object="c"
											:isShowCompetionFromCountry="country.isShowCountryListOnly" 
											@favHandler="favHandler"
											@pageIsRedirected="pageIsRedirected"
										/>
									</div>
								</div>
							</template>
			
              <div v-else class="display-flex flex-col">
                <div class="fav-modal__subtitle" v-if="!country.isShowCountryListOnly" v-show="!isNoCompetitionData">	
                	<p>{{ !userInput ? $t(`POPULAR_${tabName}`) : $t(tabName) }}</p>
                </div>
								<div v-show="isNoCompetitionDataFromCountry">
									<div class="display-flex flex-col">
										<div class="fav-modal__subtitle">
										<p>{{ $t("NO_RESULT") }}</p>
										</div>
									</div>
								</div>
                <div class="fav-modal__modal-card-wrapper fix-h display-flex flex-col">
									<FavCard
										v-for="c in result.result"
										:key="c.id"
										:keyName="c.key"
										:type="tabName"
										:countryKey="c.countryKey"
										:name="c.name"
										:id="c.id"
										:logo="c.logo"
										:isFav="c.isFav"
										:object="c"						
										@favHandler="favHandler"
										@pageIsRedirected="pageIsRedirected"
									/>
                </div>
              </div>			  
            </div>			
          </div>
        </div>
        <div class="fav-modal__btn-wrapper display-flex-center" v-show="isMobile">
        	<button class="fav-modal__btn" @click="$emit('closeModal')">{{ $t('DONE') }}</button>
        </div>
      </div>
    </div>
  </Transition>
  <PopUpSignInIndexMobile ref="PopUpSignInIndexMobileRef" :message="$i18n.t('FAV_LOGIN_TIPS')" ></PopUpSignInIndexMobile>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import FavCard from "./FavCard.vue";
import PopUpSignInIndexMobile from "@/components/user/PopUpSignInIndexMobile.vue"

export default {
  props: ["tabName"],
	components: { 
		FavCard,
		PopUpSignInIndexMobile	
	},
  data() {
    return {
		result: "",
		countryResult: "",
		userInput: "",

		isNoData: false,
		showModalTransition: false,
		globalPopularList:[],

		country: {
			isShowCountryListOnly: false,
			currentCountryDisplay: ''
		},
		
		isReadyToShowData: false,
		isNoCompetitionDataFromCountry: false,
		isNoCompetitionData: false
    };
  },
  watch: {
    isShowFavModal: {
      immediate: true,
      handler(newVal) {
        newVal
          ? (document.body.style.overflow = "hidden")
          : (document.body.style.overflow = "unset");

				if (newVal) {
					this.userInput = "";
					this.globalPopularList = [];
					this.result =  "";
					this.countryResult = "";
					this.getPopularData();
				}

				if (this.$refs.userInputRef) {
					if (newVal) {
						this.$nextTick(() => {
							this.$refs.userInputRef.focus();
						})
					} else {
						this.$refs.userInputRef.blur();
					}
				}	
      },
    },
  },
  computed: {
    ...mapGetters([
			"isLogin",
			"userInfo",
			"isMobile",
			"isShowFavModal",
			"currentSportType",
		]),
  },
  methods: {
    ...mapActions([
      "getTeamPopular",
      "getCompetitionPopular",
      "getTeamSearch",
      "getCompetitionSearch",
			"getGlobalSearch",
      "getCountrySearch",
      "addFavTeam",
      "addFavLeague",
      "unFavTeam",
      "unFavLeague",
      "toastrFavChangeEvent",
			"getFavGlobalPopular",
			"favLoginTipsChangeEvent",
			"getBasketballCompetitionPopular",
			"getFavBasketballGlobalPopular",
			"getBasketballTeamPopular",
			"getBasketballCountrySearch",
			"getBasketballCompetitionSearch",
			"getBasketballGlobalSearch",
			"getBasketballTeamSearch",
			"unFavBasketballTeam",
			"addFavBasketballTeam",
			"unFavBasketballLeague",
			"addFavBasketballLeague",
    ]),
    getPopularData() {
			this.resetCountryDisplay();

      let params = {
        token: this.userInfo.token,
        timeZone: this.$tools.getCurrentTimeZone(),
				limit: 10
      };

			this.$nextTick(async () => {
				if (this.tabName === "COMPETITIONS") {
					if (this.currentSportType == "football")
						this.result = await this.getCompetitionPopular(params);
					else if (this.currentSportType == "basketball")
						this.result = await this.getBasketballCompetitionPopular(params);
					this.handleEmptyResult(this.result);

				} else if (this.tabName === "FILTER") {
					params.limit = 5;
					let result;
					if (this.currentSportType == "football")
						result = await this.getFavGlobalPopular(params);
					else if (this.currentSportType == "basketball")
						result = await this.getFavBasketballGlobalPopular(params);
					this.globalPopularList = [];

					this.globalPopularList[0] =  {
						title: 'POPULAR_COMPETITIONS',
						titleType: 'COMPETITION',
						list: result.result.competition
					};

					this.globalPopularList[1] =  {
						title: 'POPULAR_TEAMS',
						titleType: 'TEAM',
						list: result.result.team
					};

					this.isNoData = false;
				} else {
					if (this.currentSportType == "football")
						this.result = await this.getTeamPopular(params);
					else if (this.currentSportType == "basketball")
						this.result = await this.getBasketballTeamPopular(params);
					this.handleEmptyResult(this.result);
				}
      });
    },
    async searchList(input) {
			let params = {
				token: this.userInfo.token,
				timeZone: this.$tools.getCurrentTimeZone(),
				q: input,
			};

			if (this.tabName === "COMPETITIONS") {
				if (this.currentSportType == "football") {
					this.countryResult = await this.getCountrySearch(params);
					this.result = await this.getCompetitionSearch(params);
				} else if (this.currentSportType == "basketball") {
					this.countryResult = await this.getBasketballCountrySearch(params);
					this.result = await this.getBasketballCompetitionSearch(params);
				}
				
				this.resetCountryDisplay();

				if (!this.result.result.length && !this.countryResult.result.length) {
					this.isNoData = true;
				} else this.isNoData = false;
				
				if (this.result.result.length === 0) {
					this.isNoCompetitionData = true;
				} else {
					this.isNoCompetitionData = false;
				}
			} else if (this.tabName === 'FILTER') {
				let result;
				if (this.currentSportType == "football")
					result = await this.getGlobalSearch(params);
				else if (this.currentSportType == "basketball")
					result = await this.getBasketballGlobalSearch(params);

				this.globalPopularList = [];

				if (result.result.country.length === 0 && result.result.competition.length === 0 && result.result.team.length === 0) {
					this.isNoData = true;
				} else {
					this.isNoData = false;
					if (result.result.country.length > 0) {
						this.globalPopularList.push({
							title: 'COUNTRY',
							titleType: 'COUNTRY',
							list: result.result.country
						})
					}

					if (result.result.competition.length > 0) {
						this.globalPopularList.push({
							title: 'COMPETITION',
							titleType: 'COMPETITION',
							list: result.result.competition
						})
					}

					if (result.result.team.length > 0) {
						this.globalPopularList.push({
							title: 'TEAM',
							titleType: 'TEAM',
							list: result.result.team
						})
					}
				}

				this.resetCountryDisplay();
			} else {
				if (this.currentSportType == "football")
					this.result = await this.getTeamSearch(params);
				else if (this.currentSportType == "basketball")
					this.result = await this.getBasketballTeamSearch(params);
				
				this.isReadyToShowData = true;
				if (!this.result.result.length) {
					this.isNoData = true;
				} else this.isNoData = false;
			}
    },
    async searchByCountry(id, countryName) {
			let params = {
				token: this.userInfo.token,
				timeZone: this.$tools.getCurrentTimeZone(),
				countryId: id,
			};
			let result;

			if (this.currentSportType == "football")
				result = await this.getCompetitionSearch(params);
			else if (this.currentSportType == "basketball")
				result = await this.getBasketballCompetitionSearch(params);

			this.country.isShowCountryListOnly = true;
			this.country.currentCountryDisplay = countryName;
		
			if (result.result.length === 0) {
				this.isNoCompetitionDataFromCountry = true;
			} else {
				this.isNoCompetitionDataFromCountry = false;
			}

			if (this.tabName === 'FILTER') {
				this.globalPopularList = [];

				this.globalPopularList.push({
					title: 'COUNTRY',
					titleType: 'COUNTRY',
					list: result.result
				})

			} else if(this.tabName === 'COMPETITIONS'){			
				this.result = result;
				this.countryResult = "";
			}
    },
    async favHandler(id, isFav, titleType, countryName, type, object) {
			object.isFav = !object.isFav
			let params = {
				token: this.userInfo.token,
				timeZone: this.$tools.getCurrentTimeZone(),
			};
				
			if (this.tabName === "TEAMS") {
				params = { ...params, teamId: id };

				let res;
				if (this.currentSportType == "football") {
					res = isFav
					? await this.unFavTeam(params)
					: await this.addFavTeam(params);
				} else if (this.currentSportType == "basketball") {
					res = isFav
					? await this.unFavBasketballTeam(params)
					: await this.addFavBasketballTeam(params);
				}

				this.toastrEvent(res, isFav, countryName, id, object);
			} else if (this.tabName === "COMPETITIONS") {
					if (type === 'COUNTRY') {
						this.searchByCountry(id, countryName);
					} else {
						params = { ...params, leagueId: id };
						let res;

						if (this.currentSportType == "football") {
							res = isFav
							? await this.unFavLeague(params)
							: await this.addFavLeague(params);
						} else if (this.currentSportType == "basketball") {
							res = isFav
							? await this.unFavBasketballLeague(params)
							: await this.addFavBasketballLeague(params);
						}

						this.toastrEvent(res, isFav, countryName, id, object);
					}
				
			} else if (this.tabName === 'FILTER') {
				if (titleType === 'COMPETITION') {
					params = { ...params, leagueId: id };
					let res;

					if (this.currentSportType == "football") {
						res = isFav
						? await this.unFavLeague(params)
						: await this.addFavLeague(params);
					} else if (this.currentSportType == "basketball") {
						res = isFav
						? await this.unFavBasketballLeague(params)
						: await this.addFavBasketballLeague(params);
					}

					this.toastrEvent(res, isFav, countryName, id, object);
					this.$emit("doneFavToggle");
				} else if (titleType === 'TEAM') {
					params = { ...params, teamId: id };
					let res;

					if (this.currentSportType == "football") {
						res = isFav
						? await this.unFavTeam(params)
						: await this.addFavTeam(params);
					} else if (this.currentSportType == "basketball") {
						res = isFav
						? await this.unFavBasketballTeam(params)
						: await this.addFavBasketballTeam(params);
					}

					this.toastrEvent(res, isFav, countryName, id, object);
					this.$emit("doneFavToggle");
				} else if (titleType === 'COUNTRY') {
					if (this.country.isShowCountryListOnly) {
						params = { ...params, leagueId: id };
						let res;

						if (this.currentSportType == "football") {
							res = isFav
							? await this.unFavLeague(params)
							: await this.addFavLeague(params);
						} else if (this.currentSportType == "basketball") {
							res = isFav
							? await this.unFavBasketballLeague(params)
							: await this.addFavBasketballLeague(params);
						}

						this.toastrEvent(res, isFav, countryName, id, object);
						this.$emit("doneFavToggle");
					} else {
						this.searchByCountry(id, countryName)
					}
				}
			}
    },

	handleSearchUserInput() {
		
		this.userInput = this.$refs.userInputRef.value
   		if (this.userInput === "") {
			this.countryResult = "";	
			this.getPopularData();	
        } else {
			this.searchList(this.userInput);
			//this.$refs.favContentListRef.scrollTo(0,0)
        }
	},
	toastrEvent(res, isFav, countryName, id, object) {
		if (res.result.businessCode === -1) {
			object.isFav = !object.isFav
		} else {
			let paramsToastr = {
				isOpen: true,
				msg: countryName,
				isAddedFav: isFav ? false : true,
			};

			this.toastrFavChangeEvent(paramsToastr);
		

			if (this.tabName === 'FILTER') {
				//update home page match list
				this.$emit('favModelEmit')
			}
		}
	},

	resetCountryDisplay() {
		this.country.isShowCountryListOnly = false;
		this.country.currentCountryDisplay = '';
		this.isNoCompetitionDataFromCountry = false;
	},
	handleCountryGoBack() {
		this.searchList(this.userInput);
	},
	handleEmptyResult(result) {
		if (result.result.length > 0) {
			this.isNoData = false;
		} else {
			this.isNoData = true;
		}
	},
	handleClearUserInput() {
		this.userInput = "";
		this.countryResult = "";
		this.getPopularData();
		this.$nextTick(() => {
			this.$refs.userInputRef.focus();
		})
	},
	handleContent(e) {
    	e.stopPropagation();
    },
	handleFavContentScrollEvent(event) {
		if (this.isMobile) {
			//this.$refs.userInputRef.blur();
		} else {
			this.favLoginTipsChangeEvent({isOpen: false});
		}
	},
	pageIsRedirected() {
		this.$emit("closeModal");
	},

  },

};
</script>

<style scoped>
.fav-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(32, 32, 32, 0.75);
  z-index: 10;
}
.fav-modal__wrapper {
  background: #000000;
  border-radius: 8px;
  width: 50rem;
  height: 31.5rem;
  padding: 1.5rem 0 4rem 0;
}
.fav-modal__inner-wrapper {
  width: 75%;
  margin: 0 auto;
  overflow: hidden;
  padding-right: 0.5rem;
}
.fav-modal__wrapper-list {
  width: 100%;
  max-height: 26rem;
  overflow-y: auto;
}
.search-wrapper {
  width: 100%;
  background: #35454d;
  border-radius: 1.5rem;
  padding: 0.5rem 1rem;
  margin: 1.5rem 0 1rem 0;
}
.search-clear {
  width: 1.25rem;
  height: 1.25rem;
  background: #212b30;
  border-radius: 50%;
  cursor: pointer;
}
.fav-modal__subtitle {
  font-size: 0.875rem;
  line-height: 1.25rem;
  margin: 0.5rem 0 0.625rem 0;
}
.fav-modal__modal-card-wrapper {
  gap: 0.5rem;
}
.fav-modal__close-wrapper {
  width: 94%;
  margin: 0 auto;
}
.fav-modal__close {
  background: url("../../../../static/images/icons/icon_close_white.png")
    no-repeat center center;
  background-size: 50%;
  width: 2.5rem;
  height: 2.5rem;
  cursor: pointer;
}
.fav-modal__wrapper-list::-webkit-scrollbar {
  width: 0.5rem;
  position: absolute;
}
.fav-modal__wrapper-list::-webkit-scrollbar-thumb {
  background: #4b616c;
  border-radius: 4px;
}
.fav-modal__wrapper-list::-webkit-scrollbar-track {
  background: #212b30;
  border-radius: 4px;
}
.fav-modal__wrapper-list.scrollbar-show {
  padding-right: 0.25rem;
  width: calc(100% + 0.25rem);
}


.fav-model__back{
	margin-right: .9rem;
	display: flex;
	align-items: center;
}
@media (max-width: 768px) {
  .fav-modal__wrapper {
    width: 100%;
    height: 100%;
    padding: unset;
  }
  .fav-modal__inner-wrapper {
    width: 90%;
    padding: unset;
  }
  .fav-modal__wrapper-list {
    max-height: calc(100vh - 8rem);
  }
  .fav-modal__wrapper-list::-webkit-scrollbar {
    width: 0;
  }
  .fav-modal__close {
    width: 1.5rem;
    height: 1.5rem;
    position: absolute;
    left: 1rem;
  }
  .fav-modal__close-wrapper {
    width: unset;
    padding: 0.5rem 1rem;
    position: relative;
  }
  .search-wrapper {
    height: 3rem;
    margin: 0.5rem auto;
  }
  .fav-modal__subtitle {
    margin: 1rem 0;
  }
  .fav-modal__close-title {
    font-size: 0.875rem;
    line-height: 1.25rem;
  }
  .fav-modal__btn-wrapper {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 5rem;
    background: #000;
  }
  .fav-modal__btn {
    background: #01c3f7;
    border-radius: 4px;
    padding: 0.625rem 0.75rem;
    border: none;
    font-weight: 700;
    width: 90%;
	color: black !important;
  }
  .has-btn {
    padding-bottom: 12rem;
  }

}
</style>
