import config from "@/js/config.js";
import footerLinks from "@/js/seoArticles/footer.js";

const projectName = config.projectName;
const description = `AsiaSports is a current hub where all the sports fans gather for newsfeed. Every aspect of the tournament will be made accessible at all times. When a football match is held, the live scores will be updated in real-time as it progresses.`;
const footerLinksPageMeta = {};
Object.values(footerLinks).forEach((arr) => {
  if (arr.length > 0) {
    arr.forEach((article) => {
      footerLinksPageMeta[article.id] = (route) => ({
        title: article.titleTag,
        description: article.metaDescription,
      });
    });
  }
});

export default {
  home: (route) => ({
    title: `Live Football Results, Live Scores, Fixtures, Video Highlights - ${projectName}`,
    description: description,
  }),
  highlights: (route) => ({
    title: `Football Highlights, Live Football Results, Soccer Highlights - ${projectName}`,
    description: description,
  }),
  news: (route) => ({
    title: `Watch Football Online, Soccer Video Highlights, Livestream - ${projectName}`,
    description: description,
  }),
  event: (route) => ({
    title: `Football Contests, Soccer Contests & Events - ${projectName}`,
    description: description,
  }),
  account: (route) => ({
    title: `My Profile - ${projectName}`,
    description: description,
  }),
  player: (route) => ({
    title: `${route.params.playerName
      .split("-")
      .join(" ")} Profile - ${projectName}`,
    description: description,
  }),
  matchDetail: (route) => ({
    title: `${route.params.opponent
      .split("-")
      .join(" ")} Video Highlights - ${projectName}`,
    description: description,
  }),
  highlightsDetail: (route) => ({
    title: `${route.params.opponent
      .split("-")
      .join(" ")} Video Highlights - ${projectName}`,
    description: description,
  }),
  ...footerLinksPageMeta,
};
