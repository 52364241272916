module.exports = {
  apiUrl: process.env.VUE_APP_SERVICE_URL,
  apiHkfcUrl: process.env.VUE_APP_SERVICE_HKFC_URL,
  fantasySportUrl: process.env.VUE_APP_FANTASY_URL,
  fantasySportLogoutUrl: process.env.VUE_APP_FANTASY_LOGOUT_URL,

  s3ImgUrl: process.env.VUE_APP_S3_IMG_URL,

  envAlias: process.env.VUE_APP_ENV,

  RECAPTCHA_API_KEY: process.env.VUE_APP_FIREBASE_RECAPTCHA_API_KEY,
  RECAPTCHA_AUTH_DOMAIN: process.env.VUE_APP_FIREBASE_RECAPTCHA_AUTH_DOMAIN,
  RECAPTCHA_PROJECT_ID: process.env.VUE_APP_FIREBASE_RECAPTCHA_PROJECT_ID,
  RECAPTCHA_STORAGE_BUCKET:
    process.env.VUE_APP_FIREBASE_RECAPTCHA_STORAGE_BUCKET,
  RECAPTCHA_MESSAGING_SENDER_ID:
    process.env.VUE_APP_FIREBASE_RECAPTCHA_MESSAGING_SENDER_ID,
  RECAPTCHA_APP_ID: process.env.VUE_APP_FIREBASE_RECAPTCHA_APP_ID,
  FIREBASE_VAPID_KEY: process.env.VUE_APP_FIREBASE_VAPID_KEY,
  FIREBASE_DATABASE_URL: process.env.VUE_APP_FIREBASE_DATABASE_URL,

  recaptchaSiteKey: "6LcA064fAAAAAMzGxzBK8wNpwbd9UgN2yxU7gB5E",
  hCaptchaSiteKey: process.env.VUE_APP_HCAPTCHA_SITE_KEY,
  projectName: "AsiaSports",
  mobileWidth: 765,

  defaultLocale: "en",

  displayDateFormat: "DD MMM YYYY",
  displayCNDateFormat: "DD日 MMM YYYY年",
  apiParamDateFormat: "YYYY-MM-DD",

  intervalMatchList: 10000, //10s getMatchListData apply to home page,  league page ,  team info - match page

  pageSize: 500,
  newsPageSize: 10,

  play168Url: "https://www.play168cambodia.com/player/home",
  socialMediaShareList: [
    {
      id: "fb",
      name: "SHARE_ON_Facebook",
      socialMediaUrl: "https://www.facebook.com/sharer/sharer.php?u=",
      iconUrl: "icon_fb",
    },
    {
      id: "twitter",
      name: "SHARE_ON_Twitter",
      socialMediaUrl: "https://twitter.com/share?url=",
      iconUrl: "icon_twitter",
    },
    {
      id: "line",
      name: "SHARE_ON_Line",
      socialMediaUrl: "https://social-plugins.line.me/lineit/share?url=",
      iconUrl: "icon_line",
    },
    {
      id: "selfShare",
      name: "COPY_LINK",
      socialMediaUrl: "",
      iconUrl: "icon_share",
    },
  ],

  languageList: [
    {
      country: "England",
      displayName: "English",
      displayShorterName: "EN",
      locale: "en",
      displayLocale: "en",
      selected: true,
      active: true,
      icon: "england",
      apiParam: "en",
    },
    {
      country: "Thailand",
      displayName: "ภาษาไทย",
      displayShorterName: "TH",
      locale: "th",
      displayLocale: "th",
      selected: false,
      active: true,
      icon: "thailand",
      apiParam: "th_TH",
    },
    {
      country: "Vietnam",
      displayName: "Tiếng Việt",
      displayShorterName: "VN",
      locale: "vi",
      displayLocale: "vn",
      selected: false,
      active: true,
      icon: "vietnam",
      apiParam: "vi_VN",
    },
    {
      country: "Cambodia",
      displayName: "ភាសាខ្មែរ",
      displayShorterName: "KH",
      locale: "km",
      displayLocale: "kh",
      selected: false,
      active: true,
      icon: "cambodia",
      apiParam: "km_KH",
    },
    {
      country: "China",
      displayName: "简体中文",
      displayShorterName: "简体",
      locale: "zh-Hans",
      momentLocale: "zh-cn",
      displayLocale: "zh-Hans",
      selected: false,
      active: true,
      icon: "china",
      apiParam: "zh-Hans_CN",
    },
    {
      country: "Hong Kong",
      displayName: "繁體中文",
      displayShorterName: "繁體",
      locale: "zh-Hant",
      momentLocale: "zh-hk",
      displayLocale: "zh-Hant",
      selected: false,
      active: true,
      icon: "hongkong",
      apiParam: "zh-Hant_HK",
    },
    {
      country: "Japan",
      displayName: "日本語",
      displayShorterName: "JA",
      locale: "ja",
      displayLocale: "ja",
      selected: false,
      active: true,
      icon: "japan",
      apiParam: "ja_JP",
    },
    {
      country: "Portugal",
      displayName: "Português",
      displayShorterName: "PT",
      locale: "pt",
      displayLocale: "pt",
      selected: false,
      active: true,
      icon: "portugal",
      apiParam: "pt",
    },
  ],

  countryList: [
    {
      countryCode: "BR",
      country: "Brazil",
      name: "Brazil",
      id: "Brazil",
      icon: "brazil",
      callingCode: 55,
      timeZone: "America/Sao_Paulo",
      selected: false,
      supportedLocale: ["pt", "en"],
    },
    {
      countryCode: "KH",
      country: "Cambodia",
      name: "Cambodia",
      id: "Cambodia",
      icon: "cambodia",
      callingCode: 855,
      timeZone: "Asia/Phnom_Penh",
      selected: false,
      supportedLocale: ["kh", "en"],
    },
    {
      countryCode: "CN",
      country: "China",
      name: "China",
      id: "China",
      icon: "china",
      callingCode: 86,
      timeZone: "Asia/Shanghai",
      selected: false,
      supportedLocale: ["zh-Hans", "en"],
    },
    {
      countryCode: "HK",
      country: "Hong Kong",
      name: "Hong Kong",
      id: "Hong Kong",
      icon: "hong_kong",
      callingCode: 852,
      timeZone: "Asia/Hong_Kong",
      selected: false,
      supportedLocale: ["zh-Hant", "en"],
    },
    {
      countryCode: "JP",
      country: "Japan",
      name: "Japan",
      id: "Japan",
      icon: "japan",
      callingCode: 81,
      timeZone: "Japan",
      selected: false,
      supportedLocale: ["ja", "en"],
    },
    {
      countryCode: "MY",
      country: "Malaysia",
      name: "Malaysia",
      id: "Malaysia",
      icon: "malaysia",
      callingCode: 60,
      timeZone: "Asia/Kuala_Lumpur",
      selected: false,
      supportedLocale: ["en", "zh-Hans"],
    },
    {
      countryCode: "PH",
      country: "Philippine",
      name: "Philippines",
      id: "Philippine",
      icon: "philippine",
      callingCode: 63,
      timeZone: "Asia/Manila",
      selected: false,
      supportedLocale: ["en"],
    },
    {
      countryCode: "PT",
      country: "Portugal",
      name: "Portugal",
      id: "Portugal",
      icon: "portugal",
      callingCode: 351,
      timeZone: "Europe/Lisbon",
      selected: false,
      supportedLocale: ["pt", "en"],
    },
    {
      countryCode: "SG",
      country: "Singapore",
      name: "Singapore",
      id: "Singapore",
      icon: "singapore",
      callingCode: 65,
      timeZone: "Asia/Singapore",
      selected: false,
      supportedLocale: ["en", "zh-Hans"],
    },
    {
      countryCode: "TH",
      country: "Thailand",
      name: "Thailand",
      id: "Thailand",
      icon: "thailand",
      callingCode: 66,
      timeZone: "Asia/Bangkok",
      selected: false,
      supportedLocale: ["th", "en"],
    },
    {
      countryCode: "AE",
      country: "UAE",
      name: "UAE",
      id: "UAE",
      icon: "UAE",
      callingCode: 971,
      timeZone: "Asia/Dubai",
      selected: false,
      supportedLocale: ["en"],
    },
    {
      countryCode: "VN",
      country: "Vietnam",
      name: "Vietnam",
      id: "Vietnam",
      icon: "vietnam",
      callingCode: 84,
      timeZone: "Asia/Ho_Chi_Minh",
      selected: false,
      supportedLocale: ["vn", "en"],
    },
  ],

  matchEndedStatus: [
    "Aban.",
    "AET",
    "Cancelled",
    "Cancl.",
    "FT",
    "Pen.",
    "Postp.",
    "TBA",
    "Canc.",
  ],
  footballMatchStatusFullText: {
    FT: "Full Time",
    HT: "Half Time",
    ET: "Extra Time",
    // "Aban.": "Abandoned",
    AET: "After Extra Time",
    "Canc.": "Cancelled",
    "Postp.": "Postponed",
    TBA: "To Be Announced",
    "Pen.": "Penalty",
  },
  basketballMatchStatusFullText: {
    Q1: "Quarter 1",
    Q2: "Quarter 2",
    HT: "Half Time",
    Q3: "Quarter 3",
    Q4: "Quarter 4",
    OT: "Overtime",
    TBD: "To be Determined",
    Interr: "Interrupted",
    "Canc.": "Cancelled",
    "Postp.": "Postponed",
    FT: "Full Time",
  },
  lineChannelId: "1656581900",

  pageSizeComment: 10,
  pageSizeChildComment: 100,

  app: {
    android: {
      link: "https://play.google.com/store/apps/details?id=com.asiasport.asia",
    },
    apple: {
      link: "https://apps.apple.com/my/app/asiasport/id1610463486",
    },
  },

  apk: {
    // no longer test Staging App, Production instead
    thLink: "https://asset.asiasport.com/h5/download-app/apk-th.html",
    thEnLink: "https://asset.asiasport.com/h5/download-app/apk-th-en.html",
  },

  afc2023StartDate: "2024-01-12",
  afc2023EndDate: "2024-02-10",
  afcon2023StartDate: "2024-01-13",
  afcon2023EndDate: "2024-02-12",
  uefa2024StartDate: "2024-06-15",
  uefa2024EndDate: "2024-07-15",
  copa2024StartDate: "2024-06-21",
  copa2024EndDate: "2024-07-15",
  worldCupStartDate: "2022-11-20",
  worldCupEndDate: "2022-12-18",
  localStorageUser: "user",
  localStorageUserLang: "userLangConfig",
  localStorageUserCountryCode: "userCountryCode",

  cnLocaleList: ["zh-Hans", "zh-Hant", "ja"],
};
