<template>
  <div
    class="sticky-banner__wrapper"
    :class="{'sticky-banner__move-top' : isExtraHeight}"
    :style="!isMobile && { left: leftPos + 'px', margin: bannerMargin }"
    v-if="bannerList.length > 0"
  >
    <!-- <div v-if="isLoading" class="skeleton-child h-100"></div> -->
    <div  class="h-100">
      <!-- <div class="sticky-banner__blur-top"></div> -->
      <swiper
        class="relative bottom-sticky"
        :autoplay="{ delay: 5000, disableOnInteraction: false }"
        :allowTouchMove="false"
      >
        <!-- <div class="sticky-banner__layer"></div> -->
        <swiper-slide
          v-for="(o, index) in bannerList"
          :key="index"
          @click="redirectTo(o)"
        >
          <div class="sticky-banner__container">
            <img
              class="sticky-banner__img"
              :alt="o.image"
              v-bind:src="`${s3ImgUrl}/${o.image}`"
            />
          </div>
        </swiper-slide>
        <!-- <swiper-slide>
          <div class="sticky-banner__container">
            <img
              class="sticky-banner__img"
              alt=""
              src="../../../static/images/sticky-banner.png"
            />
          </div>
        </swiper-slide> -->
      </swiper>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "swiper/vue";
import SwiperCore, { Pagination, Navigation, Autoplay } from "swiper/core";
SwiperCore.use([Pagination, Navigation, Autoplay]);

import config from "@/js/config.js";

import { mapGetters, mapActions } from "vuex";

export default {
  components: {
    Swiper,
    SwiperSlide,
  },

  data() {
    return {
      s3ImgUrl: config.s3ImgUrl,
      bannerList: [],
      isLoading: false,
      leftPos: "",
	    bannerMargin: "",

      isExtraHeight: false,
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.bannerInit();
    });
    window.addEventListener("resize", this.stickyLeft);
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.myEventHandler);
  },
  computed: {
    ...mapGetters(["isMobile", "currentLanguageObj", "currentSportType", "currentLocale","footerTabs"]),
  },
  watch: {
    currentLocale: {
      deep: true,
      handler(prev, nesw) {
        this.bannerInit(); 
      }
    },
    $route(e) {
      this.stickyLeft();
    },
    currentSportType: {
      handler(newVal, oldVal)  {
        if (newVal)
          this.bannerInit();
      }
    },
  },
  methods: {
    ...mapActions(["getFocusPictureFindPicture"]),
    stickyLeft() {
      let contentLeft = document.getElementsByClassName("container");
      let contentLeftWC = document.getElementsByClassName("match-list__container");
      let route = this.$route;
	 
      if (["home", "basketballHome"].includes(route.name)) {
           this.leftPos = contentLeft[0].getBoundingClientRect().left + 220;	
		   this.bannerMargin = "";
      } else if (route.name === "FIFA2022Matches") {
        this.$nextTick(() => {
          this.leftPos = contentLeftWC[0].getBoundingClientRect().left + 44;
          this.bannerMargin = "";
        });
      } else{
        this.bannerMargin = "0 auto"
      }
   
    },
    bannerInit() {
      this.getFocusPictureFindPictureData();
      this.stickyLeft();
      this.verifyToMoveTop();
    },
    async getFocusPictureFindPictureData() {
      this.isLoading = true;
      this.bannerList = [];

      let params = {
        deviceType: this.isMobile ? 1 : 0, //0=web; 1=webapp
        imageLang: this.currentLanguageObj.locale,
        imageType: 1, //0= banner; 1=logo
        sportType: this.currentSportType === 'football' ? 0 : 1,
      };

      const result = await this.getFocusPictureFindPicture(params);
      this.bannerList = result.result;
      this.isLoading = false;
    },

    redirectTo(o) {
      let url = o.link;
      let urlHttp = o.link.includes("http");

      if (url) {
        if (o.openWith) {
          //0:existing window, 1:new window
          window.open(urlHttp ? url : "https://" + url);
          // window.location.href = url;
        } else {
          window.location.href = urlHttp ? url : "https://" + url;
        }
      } else {
        return;
      }
    },
    verifyToMoveTop(){
      const isExtraHeight = this.footerTabs.filter(x=>x.isExtraHeight === true)[0];

      if (isExtraHeight) {
        this.isExtraHeight = true;
      } else {
        this.isExtraHeight = false;
      }
    }
  },
};
</script>

<style>
  .sticky-banner__wrapper {
    height: 5.625rem;
    bottom: 0;
    width: 37rem;
    box-sizing: border-box;
    position: sticky;
    overflow: hidden;
    z-index: 2;
  }

.sticky-banner__img {
  object-fit: contain;
  width: 100%;
}
.sticky-banner__layer {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  background-color: var(--bg-theme);
  opacity: 0.15;
  z-index: 2;
  pointer-events: none;
}

@media (max-width: 768px) {
	.sticky-banner__wrapper {	
		width: 320px;
		height: 50px;
		margin: 0 auto;
		bottom: 3.7rem;
	}
  .sticky-banner__move-top{
    bottom: 4.5rem
  }
  .sticky-banner__img {
    width: 100%;
	  height: 100%;
  }
}
</style>
