import config from "@/js/config.js";
import footerLinks from "@/js/seoArticles/footer.js";

const projectName = config.projectName;
const description = `AsiaSports is a current hub where all the sports fans gather for newsfeed. Every aspect of the tournament will be made accessible at all times. When a football match is held, the live scores will be updated in real-time as it progresses.`;
const footerLinksPageMeta = {};
Object.values(footerLinks).forEach((arr) => {
  if (arr.length > 0) {
    arr.forEach((article) => {
      footerLinksPageMeta[article.id] = (route) => ({
        title: article.titleTag,
        description: article.metaDescription,
      });
    });
  }
});

export default {
  home: (route) => ({
    title: `ライブフットボールの結果、ライブスコア、日程、ビデオハイライト - ${projectName}`,
    description: description,
  }),
  highlights: (route) => ({
    title: `フットボールのハイライト、ライブフットボールの結果、サッカーのハイライト - ${projectName}`,
    description: description,
  }),
  news: (route) => ({
    title: `オンラインでフットボールを観る、サッカーのビデオハイライト、ライブストリーム - ${projectName}`,
    description: description,
  }),
  event: (route) => ({
    title: `フットボールのコンテスト、サッカーのコンテスト＆イベント - ${projectName}`,
    description: description,
  }),
  account: (route) => ({
    title: `マイプロフィール - ${projectName}`,
    description: description,
  }),
  player: (route) => ({
    title: `${route.params.playerName
      .split("-")
      .join(" ")} プロフィール - ${projectName}`,
    description: description,
  }),
  matchDetail: (route) => ({
    title: `${route.params.opponent
      .split("-")
      .join(" ")} ビデオハイライト - ${projectName}`,
    description: description,
  }),
  highlightsDetail: (route) => ({
    title: `${route.params.opponent
      .split("-")
      .join(" ")} ビデオハイライト - ${projectName}`,
    description: description,
  }),
  ...footerLinksPageMeta,
};
